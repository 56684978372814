import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import deLocale from 'date-fns/locale/de';
import useStateRef from 'react-usestateref';

import API from '@aws-amplify/api';
import DialogContentText from '@material-ui/core/DialogContentText';
import DateFnsUtils from '@date-io/date-fns';

import LoaderButton from '../LoaderButton';

interface ReportDialogProps {
  open: boolean
  onClose: Function
  serialNumber: string
}

async function downloadFile(blob: Blob, fileName: string) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.setProperty('display', 'none');
  a.href = url;
  a.download = fileName;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  a.remove();
}

function ReportDialog({ open, onClose, serialNumber }: ReportDialogProps) {
  // const [options] = useState({});

  const [type, setType] = useState('');

  // const [firstDatum, setFirstDatum] = useState();
  // const [lastDatum, setLastDatum] = useState();

  const [minDate, setMinDate] = useState<Date>(new Date());
  const [minYear, setMinYear] = useState<Date>(new Date());

  const [genericError, setGenericError] = useState(false);
  const [noData, setNoData] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedDate, setSelectedDate, selectedDateRef] = useStateRef(new Date());
  const [lastDate, setLastDate] = useState(new Date());

  const timeframeError = false;
  const dateIsInTheFuture = new Date() <= selectedDate;
  // const timeframeError = lastDate <= selectedDate || new Date(lastDate.setDate(1)) > new Date();

  // console.log('Rerender');

  async function handleSubmit(event: any) {
    event.preventDefault();
    try {
      setGenericError(false);
      setLoading(true);

      let pdfRes;
      let filename = '';
      switch (type) {
        case 'year': {
          filename = `${serialNumber}_Jahresbericht.pdf`;
          const firstDatum = selectedDate;
          firstDatum.setDate(1);
          firstDatum.setHours(0, 0, 0, 0);
          let lastDatum = new Date();
          if (lastDatum.getFullYear() !== selectedDate.getFullYear()) {
            firstDatum.setMonth(0);
            lastDatum = new Date(firstDatum.getTime());
            lastDatum.setFullYear(firstDatum.getFullYear() + 1);
          }

          pdfRes = await API.get('emilog', 'jahresBericht', {
            response: true,
            responseType: 'blob',
            headers: {
              Accept: 'application/pdf',
            },
            queryStringParameters: {
              serialNumber,
              firstTimestamp: firstDatum.getTime(),
              lastTimestamp: lastDatum.getTime(),
            },
          });
          break;
        }
        case 'month': {
          filename = `${serialNumber}_Monatsbericht.pdf`;

          const firstDatum = selectedDate;
          firstDatum.setDate(1);
          firstDatum.setHours(0, 0, 0, 0);
          let lastDatum = new Date();
          if (lastDatum.getMonth() !== selectedDate.getMonth() || lastDatum.getFullYear() !== selectedDate.getFullYear()) {
            lastDatum = new Date(firstDatum.getTime());
            lastDatum.setMonth(firstDatum.getMonth() + 1);
          }

          pdfRes = await API.get('emilog', 'monatsBericht', {
            response: true,
            responseType: 'blob',
            headers: {
              Accept: 'application/pdf',
            },
            queryStringParameters: {
              serialNumber,
              firstTimestamp: firstDatum.getTime(),
              lastTimestamp: lastDatum.getTime(),
            },
          });
          break;
        }

        case 'mess': {
          filename = `${serialNumber}_Messbericht.pdf`;

          const firstDatum = new Date(selectedDate.getTime());
          firstDatum.setHours(0, 0, 0, 0);

          let lastDatum = new Date(lastDate.getTime());
          const today = new Date();
          lastDatum.setDate(1);
          lastDatum.setMonth(lastDatum.getMonth() + 1);
          lastDatum.setHours(0, 0, 0, 0);
          if (today.getMonth() === lastDate.getMonth() && today.getFullYear() === lastDate.getFullYear()) {
            lastDatum = new Date();
          }

          pdfRes = await API.get('emilog', 'messBericht', {
            response: true,
            responseType: 'blob',
            headers: {
              Accept: 'application/pdf',
            },
            queryStringParameters: {
              serialNumber,
              firstTimestamp: firstDatum.getTime(),
              lastTimestamp: lastDatum.getTime(),
            },
          });
          break;
        }
        case 'parameter': {
          filename = `${serialNumber}_Parameterprotokoll.pdf`;

          const firstDatum = new Date(selectedDate.getTime());
          firstDatum.setHours(0, 0, 0, 0);

          let lastDatum = new Date(lastDate.getTime());
          const today = new Date();
          lastDatum.setDate(1);
          lastDatum.setMonth(lastDatum.getMonth() + 1);
          lastDatum.setHours(0, 0, 0, 0);
          if (today.getMonth() === lastDate.getMonth() && today.getFullYear() === lastDate.getFullYear()) {
            lastDatum = new Date();
          }

          pdfRes = await API.get('emilog', 'parameterProtokoll', {
            response: true,
            responseType: 'blob',
            headers: {
              Accept: 'application/pdf',
            },
            queryStringParameters: {
              serialNumber,
              firstTimestamp: firstDatum.getTime(),
              lastTimestamp: lastDatum.getTime(),
            },
          });
          break;
        }

        default:
          break;
      }

      await downloadFile(pdfRes.data, filename);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setGenericError(true);
    }
  }

  useEffect(() => {
    async function fetchMonths() {
      try {
        setNoData(false);
        setLoading(true);
        if (!open) return;
        const res = await API.get('emilog', 'getAvailableReports', { queryStringParameters: { serialNumber } });
        const tempDate = new Date(res.firstDate);
        tempDate.setDate(1);
        setMinDate(tempDate);
        const tempYear = new Date(tempDate.getTime());
        tempYear.setDate(1);
        tempYear.setMonth(0);
        setMinYear(tempYear);
        setLoading(false);
      } catch (err) {
        setNoData(true);
        setLoading(false);
        console.log(err);
      }
    }
    setGenericError(false);
    fetchMonths();
  }, [open, serialNumber]);

  useEffect(() => {
    if (type === 'mess' || type === 'parameter') {
      setSelectedDate(minDate);
    }
  }, [type]);

  const handleClose = (_event: any, reason: string) => {
    if (reason === 'backdropClick' && loading) {
      return;
    }
    setType('');
    onClose();
  };

  function handleDateChange(event: Date) {
    setGenericError(false);
    setSelectedDate(event);
    if (type === 'year') {
      selectedDateRef.current.setMonth(0);
      setSelectedDate(selectedDateRef.current);
    }
  }

  function handleLastDateChange(event: Date) {
    setLastDate(event);
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>

      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>Bericht herunterladen</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              Wählen Sie zuerst aus, welche Art eines Berichtes Sie generieren wollen.
              Daraufhin können Sie den gewünschten Zeitraum angeben.
            </DialogContentText>
            <Grid container spacing={2}>

              <Grid item xs={12} md={6} lg={4}>
                <InputLabel id="month-label">Berichtsart</InputLabel>
                <Select
                  labelId="month-label"
                  id="month-select"
                  value={type}
                  onChange={(event) => setType(event.target.value as unknown as string)}
                  autoWidth
                  disabled={loading}
                >
                  <MenuItem key="" value="" />
                  <MenuItem key="month" value="month">Monatsbericht</MenuItem>
                  <MenuItem key="year" value="year">Jahresbericht</MenuItem>
                  <MenuItem key="mess" value="mess">Messbericht</MenuItem>
                  <MenuItem key="parameter" value="parameter">Parameterprotokoll</MenuItem>
                </Select>
              </Grid>

              {type === 'month' && (
                <Grid item xs={12} md={6}>
                  <DatePicker
                    disabled={loading}
                    cancelLabel="Abbrechen"
                    // variant="inline"
                    openTo="year"
                    views={['year', 'month']}
                    minDate={minDate}
                    maxDate={new Date()}
                    label="Monat und Jahr"
                    // helperText="Zuerst Jahr wählen"
                    maxDateMessage="Datum darf nicht in der Zukunft liegen"
                    value={selectedDate}
                    // @ts-ignore
                    onChange={handleDateChange}
                    disableFuture
                  />
                </Grid>
              )}
              {type === 'year' ? (
                <Grid item xs={12} md={6} lg={4}>
                  <DatePicker
                    disabled={loading}
                    cancelLabel="Abbrechen"
                    // variant="inline"
                    openTo="year"
                    views={['year']}
                    minDate={minYear}
                    maxDate={new Date()}
                    label="Jahr"
                    // helperText="Zuerst Jahr wählen"
                    maxDateMessage="Datum darf nicht in der Zukunft liegen"
                    value={selectedDate}
                    // @ts-ignore
                    onChange={handleDateChange}
                    disableFuture
                  />
                </Grid>
              ) : (type === 'mess' || type === 'parameter') ? (
                <>
                  <Grid item xs={12} md={6} lg={4}>

                    <DatePicker
                      disabled={loading}
                      cancelLabel="Abbrechen"
                      // variant="inline"
                      openTo="year"
                      views={['year', 'month']}
                      label="Beginn Zeitraum"
                      minDate={minDate}
                      maxDate={lastDate}
                      // helperText="Beginn des Zeitraums"
                      maxDateMessage="Start des Zeitraums darf nicht vor dem Ende liegen"
                      value={selectedDate}
                      // @ts-ignore
                      onChange={handleDateChange}
                      disableFuture
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <DatePicker
                      disabled={loading}
                      cancelLabel="Abbrechen"
                      // variant="inline"
                      openTo="year"
                      views={['year', 'month']}
                      label="Ende Zeitraum"
                      minDate={selectedDate}
                      maxDate={new Date()}
                      minDateMessage="Ende des Zeitraums darf nicht vor dem Beginn liegen"
                      maxDateMessage="Datum kann nicht in der Zukunft liegen"
                      value={lastDate}
                      // @ts-ignore
                      onChange={handleLastDateChange}
                      disableFuture
                    />
                  </Grid>
                </>
              ) : (
                null
              )}
              {genericError && (
                <Grid item>
                  <Typography color="error">Etwas ist schiefgelaufen</Typography>
                </Grid>
              )}
              {noData && (
                <Grid item>
                  <Typography color="error">Keine Daten vorhanden</Typography>
                </Grid>
              )}

            </Grid>
          </DialogContent>
          <DialogActions>
            {/* @ts-ignore */}
            <Button onClick={handleClose} disabled={loading}>Schließen</Button>
            <LoaderButton
              isLoading={loading}
              text="Download"
              fullWidth={false}
              variant="text"
              disabled={loading || (timeframeError && (type === 'mess' || type === 'parameter')) || dateIsInTheFuture}
            />
          </DialogActions>
        </form>
      </Dialog>
    </MuiPickersUtilsProvider>

  );
}

export default React.memo(ReportDialog);
