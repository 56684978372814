import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircleOutline, Search as SearchIcon, FilterList as FilterListIcon } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';

import FormControl from '@material-ui/core/FormControl';
import AddDeviceDialog from './AddDeviceDialog';
import SearchContext from '../../context/SearchContext';

const useToolbarStyles = makeStyles((theme) => ({
  toolbarRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 52,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  filterMenu: {
    padding: theme.spacing(2),
    maxWidth: '50vh',
  },
  checkboxGroup: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  checkboxLabel: {
    fontSize: '1rem',
  },
  filterIcon: {
    padding: 4,
  },
  addEmilog: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
    },
  },
  leftControls: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
}));

interface DashboardTableToolbarProps {
  devices: Device[]
  handleColumnChange: Function
  columns: AvailableColumns
}

export default function DashboardTableToolbar({
  devices, handleColumnChange, columns,
}: DashboardTableToolbarProps) {
  const classes = useToolbarStyles();
  const { filter, setFilter } = useContext(SearchContext);

  const [open, setOpen] = useState(false);

  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    handleClose();
    // newDevice();
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    handleColumnChange(event.target);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const openFilterMenu = (event: any) => {
    setMenuAnchor(event.currentTarget);
  };

  function renderLabel(value: string) {
    switch (value) {
      case 'co_m_sort':
        return 'co_m';
      case 'nox_m_sort':
        return 'nox_m';
      case 'o2_sort':
        return 'o2';
      case 'Messbericht_bis':
        return 'Messbericht';
      default:
        return value;
    }
  }

  return (
    <Toolbar className={classes.toolbarRoot}>
      <div className={classes.leftControls}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <SearchIcon />
          </Grid>
          <Grid item>
            <TextField
              label="Suche"
              onChange={handleSearch}
              value={filter}
            />
          </Grid>
          <Grid item>
            <IconButton
              onClick={openFilterMenu}
              className={classes.filterIcon}
            >
              <FilterListIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div className={classes.addEmilog}>
        <div style={{ display: 'inline-block' }}>
          <Typography component="span" color="inherit">EMI-LOG hinzufügen</Typography>
          <Tooltip title="EMI-LOG hinzufügen">
            <IconButton aria-label="hinzufügen" onClick={handleOpen}>
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <AddDeviceDialog
        open={open}
        onClose={handleClose}
        rows={devices}
        onSubmit={handleSubmit}
      />
      <Menu
        id="columnMenu"
        anchorEl={menuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        classes={{
          paper: classes.filterMenu,
        }}
      >
        <FormControl>
          <FormLabel component="legend">Spalten</FormLabel>
          <FormGroup className={classes.checkboxGroup}>
            {Object.keys(columns).map((column) => (
              <FormControlLabel
                key={column}
                classes={{
                  label: classes.checkboxLabel,
                }}
                control={
                  <Checkbox size="small" checked={columns[column]} name={column} onChange={handleChange} />
                }
                label={renderLabel(column)}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Menu>
    </Toolbar>
  );
}
