function desc(a: any, b: any, orderBy: string) {
  if (b[orderBy] < a[orderBy] || b[orderBy] === undefined) {
    return -1;
  }
  if (b[orderBy] > a[orderBy] || a[orderBy] === undefined) {
    return 1;
  }
  return 0;
}

function sortData(array: Array<any> | undefined, cmp: Function) {
  if (array === null || array === undefined) return [];
  const stabilizedThis = array.map((el: any, index: number) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

function simpleSort(array: Array<any>, cmp: Function) {
  if (array === null) return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order: SortingOrder, orderBy: string) {
  return order === 'desc' ? (a: any, b: any) => desc(a, b, orderBy) : (a: any, b: any) => -desc(a, b, orderBy);
}

export {
  desc, sortData, simpleSort, getSorting,
};
