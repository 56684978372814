/* eslint-disable react/prop-types */
import React, {
  useContext, useEffect, useState,
} from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import MainLayout from './MainLayout';

export default function PrivateRoute({ component: RouteComponent, path, exact = true }: { component: React.FC<RouteComponentProps>, path: string, exact: boolean }) {
  const { currentUser } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentUser?.loggedIn !== undefined) setLoading(false);
  }, [currentUser]);

  return (
    loading ? (
      <div />
    ) : (
      <Route
        path={path}
        exact={exact}
        render={(routeProps: RouteComponentProps) => (!currentUser?.loggedIn ? (
          <Redirect to="/login" />
        ) : (
          <MainLayout>
            <RouteComponent {...routeProps} />
          </MainLayout>
        )
        )}
      />
    )
  );
}
