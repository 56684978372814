import React, {
  useEffect, useState, useRef,
} from 'react';
import { makeStyles } from '@material-ui/styles';

import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ButtonBase from '@material-ui/core/ButtonBase';
import Skeleton from '@material-ui/lab/Skeleton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Refresh from '@material-ui/icons/Refresh';
import { PubSub } from '@aws-amplify/pubsub';
import cloneDeep from 'lodash.clonedeep';
import { Theme, Tooltip } from '@material-ui/core';
import { API } from '@aws-amplify/api';
import { saveAs } from 'file-saver';

import useStateRef from 'react-usestateref';
import StatusChip from './StatusChip';
import convertTimestamp from '../../../utils/convertTimestamp';
import LoaderButton from '../LoaderButton';

const units: Record<string, string> = {
  co_m: 'mg/Nm³',
  co: 'mg/Nm³',
  co_tr_mb: 'ppm',
  co_tr: 'ppm',
  co_f: 'ppm',
  nox_m: 'mg/Nm³',
  nox: 'mg/Nm³',
  nox_tr_mb: 'ppm',
  nox_tr: 'ppm',
  nox_f: 'ppm',
  o2_m: '%',
  o2: '%',
  o2_tr: '%',
  o2_f: '%',
  temp_m: '°C',
  temp: '°C',
  temp_roh: '°C',
  druck_m: 'mbar',
  druck: 'mbar',
  betriebsstunden: 'h',
  betriebsstunden_aktuell: 'h',
  ausfallstunden_gesamt: 'h',
  ausfallstunden_aktuell: 'h',
};

const useStyles = makeStyles((theme: Theme) => ({
  categoryHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  categoryHeader: {
    fontWeight: 900,
  },
  loaderContainer: {
    textAlign: 'center',
    marginTop: 64,
  },

  unit: {
    color: 'rgba(0, 0, 0, 0.4)',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  '@keyframes loadingBlink': {
    // from: { opacity: 1 },
    // to: { opacity: 0 },
    '50%': {
      opacity: 0.1,
    },
  },
  fetchLiveDataButton: {
    animationName: '$loadingBlink',
    animationDuration: '2s',
    animationTimingFunction: 'ease-out',
    animationIterationCount: 'infinite',
  },
}));

async function untilConditionIsTrue(conditionFunction: () => boolean) {
  const poll = (resolve: any) => {
    if (conditionFunction()) resolve();
    else setTimeout(() => poll(resolve), 100);
  };

  return new Promise(poll);
}

interface ShadowViewProps {
  handleMenuOpen: Function
  serialNumber: string
  active: boolean
}

export default function ShadowView({ handleMenuOpen, serialNumber, active }: ShadowViewProps) {
  const classes = useStyles();

  const subLive = useRef<MqttSubscription | null>(null);
  const subGet = useRef<MqttSubscription | null>(null);

  const [reported, setReported] = useState<Partial<Shadow>>({});
  const [metadata, setMetadata] = useState<Partial<ShadowMetadata>>({});
  const [desired, setDesired] = useState<Record<string, any>>({});
  const [sortedReported, setSortedReported] = useState<Partial<Shadow>>({});
  const [loading, setLoading] = useState(false);
  const [loadingLiveData, setLoadingLiveData, loadingLiveDataRef] = useStateRef(false);

  const [pdfLoading, setPdfLoading] = useState(false);

  async function requestLiveValues() {
    setLoadingLiveData(true);
    PubSub.publish(`$aws/things/EMI-LOG_${serialNumber}/shadow/update`, {
      state: {
        desired: {
          mqtt: {
            counter: 4, // Anzahl
            rate: 5, // frequenz max 5 sekunden
          },

        },
      },
    });
    PubSub.publish(`$aws/things/EMI-LOG_${serialNumber}/shadow/get`, {});
  }

  async function onPdfDownload(event: any) {
    event.preventDefault();
    setPdfLoading(true);
    try {
      requestLiveValues();
      await untilConditionIsTrue(() => loadingLiveDataRef.current === false);
      const res = await API.get('emilog', 'getShadowPdf', {
        response: true,
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
        queryStringParameters: {
          serialNumber,
          timezone: new Date().getTimezoneOffset(),
        },
      });
      const fileName = `${serialNumber}_Shadow.pdf`;
      saveAs(res.data, fileName);

      setPdfLoading(false);
    } catch (err: any) {
      console.error(err);
      setPdfLoading(false);
    }
  }

  function mapMqttResponse(value: MqttResponse) {
    if (value?.state?.desired === undefined) {
      setLoadingLiveData(false);
    }
    setReported((prevState) => ({
      ...prevState,
      ...value.state.reported,
    }
    ));
    setMetadata((prevState) => ({
      ...prevState,
      ...value.metadata.reported,
    }
    ));
    if (typeof value?.state?.desired !== 'undefined') {
      setDesired((prevState) => ({
        ...prevState,
        ...value.state.desired,
      }));
    }
  }

  async function fetchShadow() {
    const res = await API.get('emilog', 'getShadow', {
      queryStringParameters: {
        serialNumber,
      },
    }) as MqttResponse;
    setLoading(false);
    setReported((prevState) => ({
      ...prevState,
      ...res.state.reported,
    }));
    setMetadata((prevState) => ({
      ...prevState,
      ...res.metadata.reported,
    }));
  }

  useEffect(() => {
    if (subLive.current) subLive.current!.unsubscribe();
    if (subGet.current) subGet.current!.unsubscribe();
    setReported({});
    setMetadata({});
    setDesired({});
    if (active && serialNumber) {
      setLoading(true);
      fetchShadow();
      subLive.current = PubSub.subscribe(`$aws/things/EMI-LOG_${serialNumber}/shadow/update/accepted`).subscribe({
        next: ({ value }) => {
          mapMqttResponse(value);
        },
        error: ((err) => {
          console.log(err);
        }),
      });
      subGet.current = PubSub.subscribe(`$aws/things/EMI-LOG_${serialNumber}/shadow/get/accepted`).subscribe({
        next: ({ value }) => {
          mapMqttResponse(value);
        },
        error: ((err) => {
          console.log(err);
        }),
      });
      PubSub.publish(`$aws/things/EMI-LOG_${serialNumber}/shadow/update`, {
        state: {
          desired: {
            mqtt: {
              counter: 4, // Anzahl
              rate: 5, // frequenz max 5 sekunden
            },
          },
        },
      });
    }
    return function cleanUp() {
      if (subLive.current) subLive.current.unsubscribe();
      if (subGet.current) subGet.current.unsubscribe();
    };
  }, [active, serialNumber]);

  useEffect(() => {
    if (Object.keys(reported).length > 1) {
      const unsorted = cloneDeep(reported) as Partial<Shadow>;
      delete unsorted.welcome;
      delete unsorted.status_co;
      delete unsorted.status_nox;
      delete unsorted.status_temp;
      delete unsorted.co_roh;
      delete unsorted.status;
      const sorted = {
        co_m: unsorted.co_m,
        co: unsorted.co,
        co_tr_mb: unsorted.co_tr_mb,
        co_tr: unsorted.co_tr,
        co_f: unsorted.co_f,
        nox_m: unsorted.nox_m,
        nox: unsorted.nox,
        nox_tr_mb: unsorted.nox_tr_mb,
        nox_tr: unsorted.nox_tr,
        nox_f: unsorted.nox_f,
        o2_m: unsorted.o2_m,
        o2: unsorted.o2,
        o2_tr: unsorted.o2_tr,
        o2_f: unsorted.o2_f,
        temp_m: unsorted.temp_m,
        temp: unsorted.temp,
        temp_roh: unsorted.temp_roh,
        druck_m: unsorted.druck_m,
        druck: unsorted.druck,
        status_co_sensor: unsorted.status_co_sensor,
        status_nox_sensor: unsorted.status_nox_sensor,
        status_temp_sensor: unsorted.status_temp_sensor,
        status_druck_sensor: unsorted.status_druck_sensor,
        'betriebsstunden/jahr': unsorted.betriebsstunden,
        'betriebsstunden/monat': unsorted.betriebsstunden_aktuell,
        'ausfallstunden/12 monate': unsorted.ausfallstunden_gesamt,
        'ausfallstunden/monat': unsorted.ausfallstunden_aktuell,
        version: unsorted.version,
        ...unsorted,
      };
      delete sorted.betriebsstunden;
      delete sorted.betriebsstunden_aktuell;
      delete sorted.ausfallstunden_aktuell;
      delete sorted.ausfallstunden_gesamt;
      setSortedReported(sorted);
    }
  }, [reported]);

  function renderDesired() {
    if (Object.keys(desired).length > 1) {
      return (
        <>
          <Grid item md={11} className={classes.categoryHeaderContainer}>
            <Typography variant="button" className={classes.categoryHeader}>Desired</Typography>
          </Grid>
          <Grid item md={12}>

            {Object.keys(desired).map((key) => (
              <Grid container spacing={2} key={key}>
                <Grid item md={1} />
                <Grid item md={5}>
                  {key}
                </Grid>
                <Grid item md={6}>
                  {String(desired[key])}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </>
      );
    }
    return null;
  }

  function renderReported() {
    if (Object.keys(sortedReported).length > 1) {
      return (
        <>
          <Grid item xs={11} className={classes.categoryHeaderContainer}>
            <Typography variant="button" className={classes.categoryHeader}>Reported</Typography>
          </Grid>
          <Grid item xs={12}>

            {Object.keys(sortedReported).map((key: string) => {
              let values: React.ReactElement | null = null;
              const divider = ['status_co_sensor', 'betriebsstunden/jahr', 'nox_m', 'temp_m', 'o2_m', 'druck_m', 'version', 'DHCP'].includes(key) && (
                <div key={`${key}-divider`} style={{ padding: 8 }}><Divider /></div>);
              if (typeof sortedReported[key as keyof Shadow] !== 'undefined') {
                values = (
                  <Grid container spacing={2} key={key}>
                    <Grid item xs={1} />
                    <Grid item xs={6}>
                      {key}
                    </Grid>
                    <Grid item xs={5}>
                      {key.includes('status') ? (
                        <Tooltip title={convertTimestamp(metadata[key]?.timestamp)}>
                          <span>
                            {`${String(sortedReported[key as keyof Shadow])}`}
                          </span>
                        </Tooltip>
                      ) : (
                        <span>
                          {`${String(sortedReported[key as keyof Shadow])}`}
                        </span>
                      )}
                      <span className={classes.unit}>
                        {` ${units[key] || ''}`}
                      </span>
                    </Grid>
                  </Grid>
                );
              }
              return [divider, values];
            })}

          </Grid>
        </>
      );
    }
    return null;
  }

  function renderShadow() {
    return (
      <>
        {Object.keys(reported).length > 0 && (
          renderReported()
        )}
        {Object.keys(desired).length > 0 && (
          renderDesired()
        )}
      </>
    );
  }

  const renderCardTitle = () => (
    <div className={classes.cardHeader}>

      {/* @ts-ignore */}
      <ButtonBase onClick={handleMenuOpen}>
        <Typography variant="h5">Shadow</Typography>
        <KeyboardArrowDownIcon />
      </ButtonBase>
      {serialNumber && !loading ? (
        <LoaderButton
          variant="outlined"
          isLoading={pdfLoading}
          text="↓ PDF"
          onClick={onPdfDownload}
          noMargin
        />
      ) : null}
    </div>
  );

  return (
    <>
      <CardHeader
        title={(renderCardTitle())}
        style={{ position: 'relative' }}
        action={(
          <StatusChip
            status={reported?.status ?? 'unbekannt'}
            timestamp={metadata?.status?.timestamp}
            loading={loading}
          />
        )}
      />
      <Divider />
      <CardContent>
        {loading ? (
          <>
            <Skeleton style={{ marginBottom: 10 }} variant="text" />
            <Skeleton style={{ marginBottom: 10 }} variant="text" />
            <Skeleton style={{ marginBottom: 10 }} variant="text" />
          </>
        ) : (
          <Grid container spacing={1}>
            {serialNumber ? (
              <Grid item xs={12}>
                <ButtonBase onClick={requestLiveValues} style={{ fontSize: '0.88rem' }} className={loadingLiveData ? classes.fetchLiveDataButton : undefined}>
                  Letzter Live-Messwert von
                  {' '}
                  {convertTimestamp(Math.max(metadata.temp?.timestamp ?? 0, metadata.nox?.timestamp ?? 0, metadata.co?.timestamp ?? 0), true)}
                  <Refresh style={{ marginLeft: 4, fontSize: '1.3rem' }} />
                </ButtonBase>
              </Grid>
            ) : (
              null
            )}
            {renderShadow()}
          </Grid>
        )}
      </CardContent>

    </>

  );
}

// betriebsstunden im filtrt
