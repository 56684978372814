/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';

import LoaderButton from '../LoaderButton';
import FormInput from '../FormInput';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authenticating, setAuthenticating] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [passChange, setPassChange] = useState(false);
  const [newPass, setNewPass] = useState('');
  const [confirmNewPass, setConfirmNewPass] = useState('');
  const [user, setUser] = useState(null);
  const [validLength, setValidLength] = useState(null);

  useEffect(() => {
    document.title = 'Login - EMI-LOG Adminportal';
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    setAuthenticating(true);

    try {
      const res = await Auth.signIn({ username: email, password });
      if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(res);
        setAuthenticating(false);
        setPassChange(true);
      } else {
        await API.post('emilog', 'attachPolicy');
        history.push('/');
      }
    } catch (e) {
      if (e.code === 'UserNotConfirmedException') {
        setErrorText('Email ist noch nicht verifiziert');
      } else {
        setErrorText('Passwort oder Email ist falsch');
      }
      // console.log(e);
      setError(true);
      setAuthenticating(false);
    }
  }

  function validateFields() {
    return (
      newPass === confirmNewPass
    );
  }

  function validateLength() {
    if (newPass.length < 8) {
      return false;
    }
    return true;
  }

  async function handlePasswordChange(event) {
    event.preventDefault();
    setAuthenticating(true);
    // try {
    if (!validateLength()) {
      setValidLength(false);
      setAuthenticating(false);
      return;
    }
    await Auth.completeNewPassword(user, newPass);
    await API.post('emilog', 'attachPolicy');
    history.push('/');
    // } catch (e) {
    //   // console.log(e);
    //   return null;
    // }
  }

  function togglePassword() {
    setShowPassword(!showPassword);
  }

  const PasswordToggle = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={togglePassword}
      >
        {!showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  function renderChangeForm() {
    return (
      <form className={classes.form} onSubmit={handlePasswordChange}>
        <FormInput
          id="newPass"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setNewPass(e.target.value)}
          label="Neues Passwort"
          helperText={validLength === false ? 'Passwort muss mindestens 8 Zeichen lang sein' : ''}
          error={validLength === false}
          value={newPass}
          InputProps={{
            endAdornment: PasswordToggle,
          }}
        />
        <FormInput
          id="confirmNewPass"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setConfirmNewPass(e.target.value)}
          value={confirmNewPass}
          label="Neues Passwort wiederholen"
          helperText={validLength === false ? 'Passwort muss mindestens 8 Zeichen lang sein' : ''}
          error={validLength === false}
          InputProps={{
            endAdornment: PasswordToggle,
          }}
        />
        <LoaderButton
          isLoading={authenticating}
          text="Bestätigen"
          fullWidth
          disabled={!validateFields()}
        />
      </form>
    );
  }

  function renderLoginForm() {
    return (

      <form className={classes.form} onSubmit={handleSubmit}>
        <FormInput
          id="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          label="E-Mail"
        />
        <FormInput
          id="password"
          value={password}
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setPassword(e.target.value)}
          label="Passwort"
          helperText={errorText}
          error={error}
          InputProps={{
            endAdornment: PasswordToggle,
          }}
        />
        <LoaderButton
          isLoading={authenticating}
          text="Anmelden"
          fullWidth
        />
      </form>

    );
  }

  return (
    <>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Anmelden
      </Typography>
      {passChange ? renderChangeForm() : renderLoginForm()}
    </>
  );
}
