/* eslint-disable no-console */
import React, { useState, useRef, useEffect } from 'react';
import PubSub from '@aws-amplify/pubsub';
import { makeStyles } from '@material-ui/core/styles';

import LoaderButton from '../LoaderButton';
import Feedback from '../Feedback';

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    display: 'block',
    position: 'relative',
    minWidth: 160,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      marginLeft: 8,
      marginTop: -2,
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: 32,
      marginTop: -2,
    },
  },

}));

interface RebootButtonProps {
  serialNumber: string
  active: boolean
}

export default function RebootButton({ serialNumber, active }: RebootButtonProps) {
  const classes = useStyles();

  const subDisconnect = useRef<MqttSubscription | null>(null);
  const subConnect = useRef<MqttSubscription | null>(null);
  const subLive = useRef<MqttSubscription | null>(null);

  const [rebooting, setRebooting] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [success, setSuccess] = useState(false);

  const [disconnected, setDisconnected] = useState(false);

  async function handleReboot(event: any) {
    event.preventDefault();
    try {
      setRebooting(true);
      setSuccess(false);
      setAccepted(false);
      setDisconnected(false);
      subDisconnect.current = PubSub.subscribe(`$aws/events/presence/disconnected/EMI-LOG_${serialNumber}`).subscribe({
        next: () => {
          setDisconnected(true);
          setAccepted(false);
        },
      });
      subConnect.current = PubSub.subscribe(`$aws/events/presence/connected/EMI-LOG_${serialNumber}`).subscribe({
        next: () => {
          setSuccess(true);
          setDisconnected(false);
          setRebooting(false);
          setTimeout(() => {
            setSuccess(false);
          }, 5000);
        },
      });

      subLive.current = PubSub.subscribe(`$aws/things/EMI-LOG_${serialNumber}/shadow/update/accepted`).subscribe({
        next: ({ value }) => {
          if (Object.prototype.hasOwnProperty.call(value.state, 'desired')) {
            if (value.state.desired === null) {
              setAccepted(true);
            }
          }
        },
      });
      await PubSub.publish(
        `$aws/things/EMI-LOG_${serialNumber}/shadow/update`,
        // '$aws/things/EMI-LOG_98F4AB19B1EF/shadow/update',
        {
          state: {
            desired: {
              reboot: true,
            },
          },
        },
      );
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => function cleanUp() {
    if (subDisconnect.current) {
      subDisconnect.current.unsubscribe();
    }
    if (subLive.current) {
      subLive.current.unsubscribe();
    }
    if (subConnect.current) {
      subConnect.current.unsubscribe();
    }
  }, []);

  return (
    <div className={classes.buttonWrapper}>
      <LoaderButton
        text="Reboot"
        isLoading={rebooting}
        disabled={!active || rebooting}
        onClick={handleReboot}
        noMargin
        fullWidth
      />
      <Feedback
        success={success}
        successText="Gerät wieder online"
        style={{
          marginTop: 10, marginRight: 0, marginLeft: 0, position: 'absolute', right: 0, top: 30, width: '100% ',
        }}
      />
      <Feedback
        success={accepted}
        successText="Reboot angenommen"
        style={{
          marginTop: 10, marginRight: 0, marginLeft: 0, position: 'absolute', right: 0, top: 30, width: '100% ',
        }}
      />
      <Feedback
        success={disconnected}
        successText="Gerät startet neu..."
        style={{
          marginTop: 10, marginRight: 0, marginLeft: 0, position: 'absolute', right: 0, top: 30, width: '100% ',
        }}
      />
    </div>
  );
}
