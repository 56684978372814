/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import isEqual from 'react-fast-compare';
import { Link } from 'react-router-dom';
import { blue } from '@material-ui/core/colors';

import DeviceContext from '../../context/DeviceContext';
import convertTimestamp from '../../../utils/convertTimestamp';
import formatStatus from '../../../utils/formatStatus';

function convertVersionTime(timestamp: any) {
  if (typeof timestamp === 'undefined' || timestamp === 'unbekannt') {
    return '-';
  }
  const date = new Date(timestamp);
  if (Number.isNaN(date)) return '-';
  return date.toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
}

function convertTimestampShort(timestamp: any) {
  if (typeof timestamp === 'undefined' || timestamp === 'unbekannt') {
    return '-';
  }
  const date = new Date(timestamp * 1000);
  if (Number.isNaN(date)) return '-';
  return date.toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
}

const useStyles = makeStyles(() => ({
  cellWidth: {
    minWidth: '20vh',
  },
  shorten: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '20vh',
    display: 'inline-block',
  },
  link: {
    textDecoration: 'inherit',
    color: blue[900],
  },

}));

interface RowProps {
  data: DeviceWithShadow
  columns: AvailableColumns
}

function Row({
  data, columns,
}: RowProps) {
  const {
    MAC, Adresse, Gasart, Betreiber, Auftrag,
    co_m, nox_m,
    status, versionsNr, versionsDatum, Messbericht_bis,
    betriebsstunden, ausfallstunden, timestamps,
  } = data;

  function renderValue(value: number | undefined | null) {
    if (typeof value === 'undefined' || value === null) {
      return '-';
    }
    const hideValue = status === 'offline' || status === 'passiv';
    if (hideValue || !Number.isFinite(value) || typeof value === 'undefined') {
      return '-';
    }
    return Math.round(value);
  }

  const { setCurrentDevice } = useContext(DeviceContext);

  const classes = useStyles();

  function handleLinkClick() {
    setCurrentDevice(data);
  }

  return (
    <TableRow
      tabIndex={-1}
      key={MAC}
    >
      <TableCell>
        <span style={{ position: 'relative' }}>
          <Link
            to={{ pathname: `/setup/${MAC}` }}
            className={classes.link}
            onClick={handleLinkClick}
          >
            {MAC}
          </Link>
        </span>
      </TableCell>
      {columns.Betreiber && (
        <TableCell
          align="left"
          className={classes.cellWidth}
        >
          <Tooltip title={Betreiber || ''}>
            <span className={classes.shorten}>
              {Betreiber || 'unbekannt'}
            </span>
          </Tooltip>
        </TableCell>
      )}
      {columns.Adresse && (
        <TableCell
          className={classes.cellWidth}
          align="left"
        >
          <Tooltip title={Adresse || ''}>
            <span className={classes.shorten}>
              {Adresse || '-'}
            </span>
          </Tooltip>
        </TableCell>
      )}
      {columns.Auftrag && (
        <TableCell
          className={classes.cellWidth}
          align="left"
        >
          <Tooltip title={Auftrag || ''}>
            <span className={classes.shorten}>
              {Auftrag || '-'}
            </span>
          </Tooltip>
        </TableCell>
      )}
      {columns.Gasart && (
        <TableCell align="left">
          {Gasart || 'unbekannt'}
        </TableCell>
      )}
      <Tooltip
        title={`Status: ${convertTimestamp(timestamps?.status.timestamp)}` || '-'}
      >
        <TableCell>
          <span>{formatStatus(status as Status) ?? '-'}</span>
        </TableCell>
      </Tooltip>
      {columns.nox_m_sort && (
        <TableCell align="right">
          {renderValue(nox_m)}
        </TableCell>
      )}
      {columns.co_m_sort && (
        <TableCell align="right">
          {renderValue(co_m)}
        </TableCell>
      )}
      {columns.versionsNr && (
        <TableCell align="left">
          {versionsNr || '-'}
        </TableCell>
      )}
      {columns.versionsDatum && (
        <TableCell align="left">
          {convertVersionTime(versionsDatum) || '-'}
        </TableCell>
      )}
      {columns.Messbericht_bis && (
        <TableCell align="left">
          {convertTimestampShort(Messbericht_bis) || '-'}
        </TableCell>
      )}
      {columns.betriebsstunden && (
        <TableCell align="right">
          {(!Number.isFinite(betriebsstunden) || betriebsstunden === undefined) ? '-' : betriebsstunden}
        </TableCell>
      )}
      {columns.ausfallstunden && (
        <TableCell align="right">
          {(!Number.isFinite(ausfallstunden) || ausfallstunden === undefined) ? '-' : ausfallstunden}
        </TableCell>
      )}
    </TableRow>
  );
}

export default React.memo(Row, isEqual);
// export default Row;
