/* eslint-disable no-console */

import React, { useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { useMediaQuery } from '@material-ui/core';

import LoaderButton from './LoaderButton';

import CsvDialog from './CsvDialog';
import ProtocolDialog from './ProtocolDialog';

import RebootButton from './Setup/RebootButton';
import SelectSearch from './SelectSearch';
import DeviceContext from '../context/DeviceContext';
import BerichteButton from './Setup/BerichteButton';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiFilledInput-input': {
      padding: '6px 20px 6px 8px',
    },
    '.MuiCardHeader-action': {
      margin: 0,
    },
  },
  header: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 6,
    },
    marginBottom: theme.spacing(1),
  },
  selectWrapper: {
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
  },
  loader: {
    position: 'absolute',
    left: 302,
    top: 7,
    [theme.breakpoints.down('xs')]: {
      margin: '8px 10px',
      // position: 'absolute',
      top: -2,
      left: 175,
    },
  },
  buttonWrapper: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      marginLeft: 8,
      marginTop: -2,
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: 32,
      marginTop: -2,
    },
  },
  controlWrapper: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'space-between',
    // flexDirection: 'column',
  },
}));

interface SetupHeaderProps {
  hideButtons?: boolean
  children?: React.ReactElement[] | null
  showBetreiber?: boolean
}

export default function SetupHeader({ hideButtons = false, children = null, showBetreiber = false }: SetupHeaderProps) {
  const {
    currentDevice, loading,
  } = useContext(DeviceContext);

  const classes = useStyles();

  const mobile = useMediaQuery('(max-width:600px)');

  const [csvOpen, setCsvOpen] = useState(false);
  const [protocolOpen, setProtocolOpen] = useState(false);

  const renderCsvButton = () => (
    <div className={classes.buttonWrapper}>
      <LoaderButton
        text="↓ CSV"
        disabled={typeof currentDevice?.serialNumber === 'undefined'}
        onClick={() => setCsvOpen(true)}
        noMargin
        isLoading={false} // Öffnet nur Dialog
        fullWidth
      />
    </div>
  );

  const renderProtocolButton = () => (
    <div className={classes.buttonWrapper}>
      <LoaderButton
        isLoading={false} // Öffnet nur Dialog
        text="↓ Protokoll"
        disabled={typeof currentDevice?.serialNumber === 'undefined'}
        onClick={() => setProtocolOpen(true)}
        noMargin
        fullWidth
      />
    </div>
  );

  const renderHeader = () => (

    <Grid container spacing={2} justifyContent={mobile ? 'space-between' : 'flex-start'} alignItems="center">
      <Grid item xs="auto" sm="auto" md="auto" lg="auto">
        <Typography variant="h5" display="inline">
          EMI-LOG
        </Typography>
      </Grid>
      <Grid item xs={12} sm="auto" md="auto" lg="auto">
        <SelectSearch loading={loading} />
      </Grid>
      {showBetreiber ? (
        <Grid item>
          <Typography variant="h6" display="inline">
            {currentDevice.Betreiber}
          </Typography>
        </Grid>
      ) : (
        null
      )}
      {!hideButtons ? (
        <>
          <Grid item xs={6} sm={6} md="auto" lg="auto">
            {renderCsvButton()}
          </Grid>
          <Grid item xs={6} sm={6} md="auto" lg="auto">
            <BerichteButton />
          </Grid>
          <Grid item xs={12} sm={6} md="auto" lg="auto">
            {renderProtocolButton()}
          </Grid>
          <Grid item xs={12} sm={6} md="auto" lg="auto">
            <RebootButton serialNumber={currentDevice ? currentDevice.serialNumber : ''} active={typeof currentDevice?.serialNumber !== 'undefined'} />
          </Grid>
        </>
      ) : (
        children
      )}
    </Grid>
  );

  return (

    <div className={classes.header}>
      {renderHeader()}
      <CsvDialog
        serial={currentDevice?.serialNumber}
        open={csvOpen}
        onClose={() => setCsvOpen(false)}
      />
      <ProtocolDialog
        serialNumber={currentDevice?.serialNumber}
        open={protocolOpen}
        onClose={() => setProtocolOpen(false)}
      />
    </div>

  );
}
