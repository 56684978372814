/* eslint-disable no-console */
import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import propTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';

import LoaderButton from '../LoaderButton';
import Feedback from '../Feedback';
import CsvTransferDialog from './ConfirmTransferDialog';
import DeviceContext from '../../context/DeviceContext';

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectWrapper: {
    flexGrow: 1,
  },
  targetSelect: {
    [theme.breakpoints.up('sm')]: {
      width: '70%',
      marginTop: theme.spacing(1),
    },
  },
}));

export default function Transfer({
  serialNumber, active,
}) {
  Transfer.propTypes = {
    serialNumber: propTypes.string,
    active: propTypes.bool.isRequired,
  };

  Transfer.defaultProps = {
    serialNumber: '',
  };
  const {
    devices,
  } = useContext(DeviceContext);

  const classes = useStyles();
  const [target, setTarget] = useState(null);

  const [open, setOpen] = useState(false);

  const [sameDevice, setSameDevice] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  function handleOpen() {
    if (serialNumber === target.MAC) {
      setSameDevice(true);
    } else {
      setSuccess(false);
      setSameDevice(false);
      setOpen(true);
    }
  }

  function handleClose() {
    setOpen(false);
  }

  function renderTitle() {
    return (
      <span className={classes.titleWrapper}>
        <span>Transfer</span>
        <Feedback
          error={error}
          success={success}
          successText="Daten erfolgreich übertragen"
        />
        <Feedback error={sameDevice} errorText="Transfer auf gleiche Seriennummer nicht zulässig" />
      </span>
    );
  }

  return (
    <Card>
      <CardHeader
        title={(
          renderTitle()
        )}
      />
      <Divider />
      <CardActions className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>

            <Autocomplete
              className={classes.targetSelect}
              value={target}
              onChange={(event, newValue) => {
                setTarget(newValue);
              }}
              disabled={!active}
              options={devices || []}
              getOptionLabel={(option) => (option.MAC ? option.MAC : '')}
              getOptionSelected={(option, value) => (value.value === option.MAC)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>

            <LoaderButton
              isLoading={false}
              disabled={!active || !target}
              text="Transfer durchführen"
              onClick={handleOpen}
              fullWidth
            />
          </Grid>
        </Grid>
      </CardActions>
      <CsvTransferDialog
        onClose={handleClose}
        open={open}
        serialNumber={serialNumber}
        target={target}
        setTarget={setTarget}
        error={error}
        success={success}
        setError={setError}
        setSuccess={setSuccess}
      />
    </Card>
  );
}
