/* eslint-disable no-param-reassign */
export default function filterData(data: Device[], filter: string) {
  const filterLower = filter.toLowerCase();
  const filtered = data.filter(
    (device) => {
      if (device.serialNumber === undefined) {
        console.log(device);
      }
      if (typeof device.Betreiber === 'undefined') {
        device.Betreiber = '';
      }
      if (typeof device.Adresse === 'undefined') {
        device.Adresse = '';
      }
      if (typeof device.Auftrag === 'undefined') {
        device.Auftrag = '';
      }

      return (
        device.serialNumber?.toLowerCase()?.includes(filterLower)
        || device.Betreiber?.toLowerCase()?.includes(filterLower)
        || device.Adresse?.toLowerCase()?.includes(filterLower)
        || device.Auftrag?.toLowerCase()?.includes(filterLower));
    },
  );
  return filtered;
}
