import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useContext, useState } from 'react';
import LoaderButton from '../LoaderButton';
import DeviceContext from '../../context/DeviceContext';
import BerichtDialog from './BerichteDialog';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    '.MuiFilledInput-input': {
      padding: '6px 20px 6px 8px',
    },
    '.MuiCardHeader-action': {
      margin: 0,
    },
  },
  header: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 6,
    },
  },
  selectWrapper: {
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
  },
  loader: {
    position: 'absolute',
    left: 302,
    top: 7,
    [theme.breakpoints.down('xs')]: {
      margin: '8px 10px',
      // position: 'absolute',
      top: -2,
      left: 175,
    },
  },
  buttonWrapper: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      marginLeft: 8,
      marginTop: -2,
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: 32,
      marginTop: -2,
    },
  },
  controlWrapper: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'space-between',
    // flexDirection: 'column',
  },
}));

export default function BerichteButton() {
  const [dialogOpen, setDialogOpen] = useState(false);

  const {
    currentDevice, loading,
  } = useContext(DeviceContext);

  const classes = useStyles();

  return (
    <div className={classes.buttonWrapper}>
      <LoaderButton
        isLoading={false} // Öffnet nur Dialog
        text="↓ Berichte"
        disabled={currentDevice?.serialNumber === undefined || loading}
        onClick={() => setDialogOpen(true)}
        noMargin
        fullWidth
      />
      <BerichtDialog
        serialNumber={currentDevice?.serialNumber}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </div>
  );
}
