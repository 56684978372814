import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import SetupHeader from '../SetupHeader';
import KlassierungTable from './KlassierungTable';
import DeviceContext from '../../context/DeviceContext';

export default function Klassierung() {
  const { serialNumber } = useParams<PathParams>();

  const {
    setCurrentDevice, devices,
  } = useContext(DeviceContext);

  useEffect(() => {
    if (serialNumber === '' || typeof serialNumber === 'undefined') {
      document.title = 'Klassierung - EMI-LOG Adminportal';
    }
    devices.forEach((device) => {
      if (device.MAC === serialNumber) {
        setCurrentDevice(device);
        document.title = `Klassierung ${serialNumber} - EMI-LOG Adminportal`;
      }
    });
  }, [serialNumber, devices, setCurrentDevice]);

  return (
    <Container maxWidth="md">
      <SetupHeader hideButtons />
      <KlassierungTable />
    </Container>
  );
}
