import React, {
  useContext, useEffect,
} from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { useParams } from 'react-router-dom';

import DAC from './DAC';
import ResetStunden from './ResetStunden';
import ResetWerte from './ResetDaten';
import Transfer from './Transfer';
import DeleteDevice from './DeleteDevice';
import Wartung from './Wartung';

import SetupHeader from '../SetupHeader';

import DeviceContext from '../../context/DeviceContext';
import Messbericht from './Messbericht';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiFilledInput-input': {
      padding: '6px 20px 6px 8px',
    },
    '.MuiCardHeader-action': {
      margin: 0,
    },
  },
  content: {
    marginTop: theme.spacing(1),
    // flex: 1,
  },
  container: {
    width: '80vw',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      padding: 0,
      width: '90vw',
    },
  },
  doubleCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  expandingCard: {
    display: 'flex',
    flexGrow: 1,
  },
}));

export default function Einstellungen() {
  const {
    currentDevice, setCurrentDevice, devices,
  } = useContext(DeviceContext);

  const { serialNumber } = useParams<PathParams>();
  const active = typeof currentDevice?.MAC !== 'undefined';

  useEffect(() => {
    if (serialNumber === '' || typeof serialNumber === 'undefined') {
      document.title = 'Weitere Einstellungen - EMI-LOG Adminportal';
    }
    devices.forEach((device) => {
      if (device.MAC === serialNumber) {
        setCurrentDevice(device);
        document.title = `Weitere Einstellungen ${serialNumber} - EMI-LOG Adminportal`;
      }
    });
  }, [serialNumber, devices, setCurrentDevice]);

  const classes = useStyles();
  return (
    <Container className={classes.container} maxWidth="xl">
      <SetupHeader />
      <Grid container spacing={2} className={classes.content} justifyContent="center">
        <Grid item lg={5} className={classes.expandingCard}>
          <Wartung
            serialNumber={currentDevice?.MAC ?? ''}
            active={active}
          />
        </Grid>
        <Grid item lg={7} xs={12}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12}>
              <ResetStunden
                active={active}
                serialNumber={currentDevice?.MAC}
              />

            </Grid>
            <Grid item xs={12} lg={12}>
              <Transfer
                serialNumber={currentDevice?.MAC}
                active={active}
              />
            </Grid>
          </Grid>

        </Grid>
        <Grid item xs={12} lg={5}>
          <DAC />
        </Grid>
        <Grid item xs={12} lg={3} container>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} lg={12}>
              <ResetWerte />
            </Grid>
            <Grid item xs={12} lg={12} style={{ alignSelf: 'flex-end' }}>
              <DeleteDevice />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Messbericht />
        </Grid>

      </Grid>
    </Container>
  );
}
