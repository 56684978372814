import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// import isEqual from 'react-fast-compare';

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

interface HeadCell {
  id: string
  numeric: boolean
  label: string
}

const headCells: HeadCell[] = [
  {
    id: 'MAC', numeric: false, label: 'Seriennummer',
  },
  {
    id: 'Betreiber', numeric: false, label: 'Betreiber',
  },
  {
    id: 'Adresse', numeric: false, label: 'Adresse',
  },
  {
    id: 'Auftrag', numeric: false, label: 'Auftrag',
  },
  {
    id: 'Gasart', numeric: false, label: 'Gasart',
  },
  {
    id: 'status', numeric: false, label: 'Status',
  },
  {
    id: 'nox', numeric: true, label: 'Live NOₓ*',
  },
  {
    id: 'nox_m_sort', numeric: true, label: 'HMW NOₓ*',
  },

  {
    id: 'co_m_sort', numeric: true, label: 'HMW CO*',
  },
  {
    id: 'temp', numeric: true, label: 'Temperatur',
  },
  {
    id: 'versionsNr', numeric: false, label: 'Versionsnr.',
  },
  {
    id: 'versionsDatum', numeric: false, label: 'Versionsdatum',
  },
  {
    id: 'Messbericht_bis', numeric: false, label: 'Messbericht',
  },
  {
    id: 'betriebsstunden', numeric: true, label: 'Betriebsstd.',
  },
  {
    id: 'ausfallstunden', numeric: true, label: 'Ausfallstd.',
  },
];

interface DashboardTableHeadProps {
  onRequestSort: Function
  order: 'asc' | 'desc'
  orderBy: string
  columns: AvailableColumns

}

function DashboardTableHead({
  onRequestSort, order, orderBy, columns,
}: DashboardTableHeadProps) {
  const classes = useStyles();

  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          (columns[headCell.id] || headCell.id === 'MAC' || headCell.id === 'status') ? (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            null
          )
        ))}
      </TableRow>
    </TableHead>
  );
}

// export default React.memo(DashboardTableHead, isEqual);
export default (DashboardTableHead);
