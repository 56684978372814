import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import API from '@aws-amplify/api';

import AnlagenInfos from './AnlagenInfos';
import Kalibrierung from './Kalibrierung';
import DataCard from './DataCard';
// import Messbericht from './Messbericht';

import SetupHeader from '../SetupHeader';

import TempSettings from './TempSettings';
import { useRouting } from '../../../utils/useRouting';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    '.MuiFilledInput-input': {
      padding: '6px 20px 6px 8px',
    },
    '.MuiCardHeader-action': {
      margin: 0,
    },
  },
  header: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 6,
    },
  },
  selectWrapper: {
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  content: {
    marginTop: theme.spacing(1),
    // flex: 1,
  },
  container: {
    width: '95vw',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      padding: 0,
      width: '90vw',
    },
  },
  loader: {
    position: 'absolute',
    left: 460,
    top: 4,
    [theme.breakpoints.down('xs')]: {
      margin: '8px 10px',
      // position: 'absolute',
      top: -2,
      left: 175,
    },
  },
  buttonWrapper: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 32,
      marginTop: -2,
    },
  },
  controlWrapper: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'space-between',
    // flexDirection: 'column',
  },
  expandingCard: {
    display: 'flex',
    flexGrow: 1,
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

interface GasartenState {
  [key: string]: Gasart
}

export default function Einstellungen() {
  const {
    currentDevice,
  } = useRouting('Setup');

  const classes = useStyles();

  const [gasarten, setGasarten] = useState<GasartenState>({});

  async function fetchGasarten() {
    try {
      const res: Gasart[] = await API.get('emilog', 'gasarten', {});
      const temp: Record<string, Gasart> = {};
      res.forEach((element: Gasart) => {
        temp[element.Gasart] = {
          Gasart: element.Gasart,
          NOx_alarm: element.NOx_alarm,
          NOx_warn: element.NOx_warn,
          CO_alarm: element.CO_alarm,
          CO_warn: element.CO_warn,
          O_B: element.O_B,
        };
      });
      setGasarten(temp);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchGasarten();
  }, []);

  return (
    <Container className={classes.container} maxWidth="xl">
      <SetupHeader />
      <Grid container spacing={2} className={classes.content}>
        <Grid item lg={4} className={classes.expandingCard}>
          <DataCard
            serialNumber={currentDevice?.serialNumber}
            active={typeof currentDevice?.serialNumber !== 'undefined'}
          />
        </Grid>
        <Grid item lg={5}>
          <AnlagenInfos
            gasarten={gasarten}
          />
        </Grid>
        <Grid item lg={3} className={classes.spaceBetween}>
          <Kalibrierung />
          <TempSettings />
        </Grid>
      </Grid>
    </Container>
  );
}
