import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import API from '@aws-amplify/api';
import InputAdornment from '@material-ui/core/InputAdornment';

import LoaderButton from '../LoaderButton';
import Feedback from '../Feedback';
import DeviceContext from '../../context/DeviceContext';
import { onlyValidNumbers } from '../../../utils/onlyValidNumbers';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 32,
  },
  card: {
    flexGrow: 2,
    flexBasis: 0,
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: 16,
  },
  section: {
    padding: theme.spacing(2),
  },
  input: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  action: {
    marginTop: '-3px!important',
  },
  unit: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '0.95rem',
  },
}));

function DAC() {
  const { currentDevice, setCurrentDevice } = useContext(DeviceContext);
  const active = currentDevice.serialNumber !== undefined;

  const [NOx_20, setNOx_20] = useState<number | string | undefined>(undefined);
  const [NOx_16, setNOx_16] = useState<number | string | undefined>(undefined);
  const [NOx_8, setNOx_8] = useState<number | string | undefined>(undefined);
  const [NOx_4, setNOx_4] = useState<number | string | undefined>(undefined);
  const [CO_20, setCO_20] = useState<number | string | undefined>(undefined);
  const [CO_4, setCO_4] = useState<number | string | undefined>(undefined);
  const [Temp_20, setTemp_20] = useState<number | string | undefined>(undefined);
  const [Temp_4, setTemp_4] = useState<number | string | undefined>(undefined);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const classes = useStyles();

  async function handleSubmit(event: React.ChangeEvent<HTMLFormElement>) {
    try {
      setLoading(true);
      event.preventDefault();
      await API.post('emilog', 'updateDAC', {
        body: {
          serialNumber: currentDevice.serialNumber,
          fields: {
            DAC_NOx_20: (NOx_20 === undefined || NOx_20 === '') ? null : NOx_20,
            DAC_NOx_16: (NOx_16 === undefined || NOx_16 === '') ? null : NOx_16,
            DAC_NOx_8: (NOx_8 === undefined || NOx_8 === '') ? null : NOx_8,
            DAC_NOx_4: (NOx_4 === undefined || NOx_4 === '') ? null : NOx_4,
            DAC_CO_20: (CO_20 === undefined || CO_20 === '') ? null : CO_20,
            DAC_CO_4: (CO_4 === undefined || CO_4 === '') ? null : CO_4,
            DAC_Temp_20: (Temp_20 === undefined || Temp_20 === '') ? null : Temp_20,
            DAC_Temp_4: (Temp_4 === undefined || Temp_4 === '') ? null : Temp_4,
          },
        },
      });

      setLoading(false);
      setCurrentDevice((prevState: Device) => ({
        ...prevState,
        DAC_NOx_20: NOx_20,
        DAC_NOx_16: NOx_16,
        DAC_NOx_8: NOx_8,
        DAC_NOx_4: NOx_4,
        DAC_CO_20: CO_20,
        DAC_CO_4: CO_4,
        DAC_Temp_20: Temp_20,
        DAC_Temp_4: Temp_4,
      }));
      setSuccess(true);
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  }

  useEffect(() => {
    setSuccess(false);
    setNOx_20(currentDevice.DAC_NOx_20);
    setNOx_16(currentDevice.DAC_NOx_16);
    setNOx_8(currentDevice.DAC_NOx_8);
    setNOx_4(currentDevice.DAC_NOx_4);
    setCO_20(currentDevice.DAC_CO_20);
    setCO_4(currentDevice.DAC_CO_4);
    setTemp_20(currentDevice.DAC_Temp_20);
    setTemp_4(currentDevice.DAC_Temp_4);
  }, [currentDevice]);

  return (
    <Card className={classes.card}>

      <CardHeader
        title="DAC"
        classes={{
          action: classes.action,
        }}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>

          <Grid item lg={4} xs={6}>
            <TextField
              size="small"
              label="NOₓ_20"
              className={classes.input}
              disabled={!active}
              variant="outlined"
              value={NOx_20 !== undefined ? NOx_20 : ''}
              onChange={(e) => setNOx_20(onlyValidNumbers(e.target.value))}
              InputProps={{
                endAdornment:
                  // eslint-disable-next-line react/jsx-indent
                  <InputAdornment position="end" disableTypography className={classes.unit}>mg/Nm³</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item lg={4} xs={6}>
            <TextField
              size="small"
              label="NOₓ_16"
              className={classes.input}
              disabled={!active}
              value={NOx_16 !== undefined ? NOx_16 : ''}
              variant="outlined"
              onChange={(e) => setNOx_16(onlyValidNumbers(e.target.value))}
              InputProps={{
                endAdornment:
                  // eslint-disable-next-line react/jsx-indent
                  <InputAdornment position="end" disableTypography className={classes.unit}>mg/Nm³</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item lg={4} xs={6}>
            <TextField
              size="small"
              label="NOₓ_8"
              className={classes.input}
              disabled={!active}
              value={NOx_8 !== undefined ? NOx_8 : ''}
              variant="outlined"
              onChange={(e) => setNOx_8(onlyValidNumbers(e.target.value))}
              InputProps={{
                endAdornment:
                  // eslint-disable-next-line react/jsx-indent
                  <InputAdornment position="end" disableTypography className={classes.unit}>mg/Nm³</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item lg={4} xs={6}>
            <TextField
              size="small"
              label="NOₓ_4"
              className={classes.input}
              disabled={!active}
              value={NOx_4 !== undefined ? NOx_4 : ''}
              variant="outlined"
              onChange={(e) => setNOx_4(onlyValidNumbers(e.target.value))}
              InputProps={{
                endAdornment:
                  // eslint-disable-next-line react/jsx-indent
                  <InputAdornment position="end" disableTypography className={classes.unit}>mg/Nm³</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item lg={4} xs={6}>
            <TextField
              size="small"
              label="CO_20"
              className={classes.input}
              disabled={!active}
              value={CO_20 !== undefined ? CO_20 : ''}
              variant="outlined"
              onChange={(e) => setCO_20(onlyValidNumbers(e.target.value))}
              InputProps={{
                endAdornment:
                  // eslint-disable-next-line react/jsx-indent
                  <InputAdornment position="end" disableTypography className={classes.unit}>mg/Nm³</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item lg={4} xs={6}>
            <TextField
              size="small"
              label="CO_4"
              className={classes.input}
              disabled={!active}
              value={CO_4 !== undefined ? CO_4 : ''}
              variant="outlined"
              onChange={(e) => setCO_4(onlyValidNumbers(e.target.value))}
              InputProps={{
                endAdornment:
                  // eslint-disable-next-line react/jsx-indent
                  <InputAdornment position="end" disableTypography className={classes.unit}>mg/Nm³</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item lg={4} xs={6}>
            <TextField
              size="small"
              label="Temp_20"
              className={classes.input}
              disabled={!active}
              value={Temp_20 !== undefined ? Temp_20 : ''}
              variant="outlined"
              onChange={(e) => setTemp_20(onlyValidNumbers(e.target.value))}
              InputProps={{
                endAdornment:
                  // eslint-disable-next-line react/jsx-indent
                  <InputAdornment position="end" disableTypography className={classes.unit}>°C</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item lg={4} xs={6}>
            <TextField
              size="small"
              label="Temp_4"
              className={classes.input}
              disabled={!active}
              value={Temp_4 !== undefined ? Temp_4 : ''}
              variant="outlined"
              onChange={(e) => setTemp_4(onlyValidNumbers(e.target.value))}
              InputProps={{
                endAdornment:
                  // eslint-disable-next-line react/jsx-indent
                  <InputAdornment position="end" disableTypography className={classes.unit}>°C</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.cardActions}>
        <Feedback
          error={error}
          success={success}
        />
        <LoaderButton
          isLoading={loading}
          disabled={!active || loading}
          onClick={handleSubmit}
        />
      </CardActions>
    </Card>

  );
}

export default DAC;
