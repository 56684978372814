/* eslint-disable no-console */
import React, {
  createContext, useEffect, useState, useContext, useMemo,
} from 'react';
import API from '@aws-amplify/api';
import AuthContext from './AuthContext';

interface ContextProps {
  currentDevice: Device
  setCurrentDevice: Function
  devices: DeviceWithShadow[]
  setDevices?: Function
  loading: boolean
  setLoading?: React.SetStateAction<boolean>
  fetchDevices: Function
}

const DeviceContext = createContext<ContextProps>({
  currentDevice: {
    serialNumber: '',
  },
  devices: [],
  setCurrentDevice: () => { },
  loading: false,
  fetchDevices: () => { },
});

/*
  Enthält alle verfügbaren Geräte und das aktuell ausgewählte Gerät
*/
function DeviceProvider({ children }: { children: React.ReactChild }) {
  const { currentUser } = useContext(AuthContext);
  const [currentDevice, setCurrentDevice] = useState<DeviceWithShadow | {}>({});
  const [devices, setDevices] = useState<DeviceWithShadow[]>([]);
  const [loading, setLoading] = useState(false);

  async function fetchDevices() {
    try {
      setLoading(true);
      const res = await API.get('emilog', 'dataWithShadow', {}) as DeviceWithShadow[];
      setLoading(false);
      if (process.env.REACT_APP_STAGE === 'dev') {
        const temp = res.filter((anlage: Device) => anlage?.Betreiber?.includes('ElektroSolid') || anlage.Betreiber?.includes('Holzofen'));
        setDevices(temp);
      } else {
        setDevices(res);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  useEffect(() => {
    if (currentUser?.loggedIn) {
      fetchDevices();
    }
  }, [currentUser]);

  const value = useMemo(() => ({
    currentDevice: currentDevice as Device,
    setCurrentDevice,
    devices,
    setDevices,
    loading,
    fetchDevices,
  }), [devices, currentDevice, loading]);

  return (
    <DeviceContext.Provider value={value}>
      {children}
    </DeviceContext.Provider>
  );
}

export default DeviceContext;
export { DeviceProvider };
