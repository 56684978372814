import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import propTypes from 'prop-types';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import PubSub from '@aws-amplify/pubsub';
import API from '@aws-amplify/api';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import { TextField } from '@material-ui/core';
import useStateRef from 'react-usestateref';

import LoaderButton from '../LoaderButton';
import Feedback from '../Feedback';
import { getVersionTimestamp } from '../../../utils/trimVersion';

const compatibleVersionTimestamp = new Date('2021/03/02').getTime();

function convertDate(date) {
  let month;
  if (date.getMonth() + 1 < 10) {
    month = `0${date.getMonth() + 1}`;
  } else {
    month = String(date.getMonth() + 1);
  }
  const year = date.getFullYear();
  return parseInt(`${year}${month}`, 10);
}

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    marginBottom: theme.spacing(1),
  },
  toggle: {
    padding: '6px 16px',
  },
  toggleGroup: {
    marginTop: -3,
    marginBottom: -3,
  },
  cardContent: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));

export default function ResetStunden({ serialNumber, active }) {
  ResetStunden.propTypes = {
    serialNumber: propTypes.string,
    active: propTypes.bool.isRequired,
  };

  ResetStunden.defaultProps = {
    serialNumber: '',
  };
  const classes = useStyles();

  const subLive = useRef(null);
  const subGet = useRef(null);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading, loadingRef] = useStateRef(false);
  const [error, setError] = useState(false);
  const [rightVersion, setRightVersion] = useState(false);

  const [mode, setMode] = useState('einzeln');

  const [date, setDate] = useState(new Date());
  const [month, setMonth] = useState(convertDate(new Date()));
  const [firstDate, setFirstDate] = useState(null);

  const [currentHours, setCurrentHours, currentHoursRef] = useStateRef(0);

  const [totalHours, setTotalHours, totalHoursRef] = useStateRef(0);

  const [maxDays, setMaxDays] = useState(0);
  const [days, setDays] = useState('');

  const [data, setData] = useState([]);

  const [resetOk, setResetOk] = useState(false);

  const [offline, setOffline] = useState(true);

  const handleToggle = (event, newMode) => {
    setMode(newMode);
  };

  const initReset = async () => {
    setLoading(true);
    setError(false);
    setSuccess(false);
    try {
      if (mode === 'gesamt') {
        await PubSub.publish(
          `$aws/things/EMI-LOG_${serialNumber}/shadow/update`,
          {
            state: {
              desired: {
                'reset-stunden': true,
              },
            },
          },
        );
      } else {
        // bei Monaten die länger als 12 Monate her sind, nur in DDB löschen
        const tempDate = new Date(new Date(new Date(date.valueOf())
          .setMonth(date.getMonth() + 12)).setHours(0, 0, 0, 0));
        const today = new Date();
        if (tempDate >= new Date(today.setHours(0, 0, 0, 0))) {
          let monat;

          if (month === convertDate(today)) {
            monat = 12;
          } else {
            monat = date.getUTCMonth();
          }
          await PubSub.publish(
            `$aws/things/EMI-LOG_${serialNumber}/shadow/update`,
            {
              state: {
                desired: {
                  'loesche-as': {
                    monat,
                    stunden: days * 24,
                  },
                },
              },
            },
          );
        } else {
          setResetOk(true);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  async function fetch() {
    try {
      const res = await API.get('emilog', 'getHours', { queryStringParameters: { serialNumber } });
      setData(res);
      const temp = String(res[res.length - 1].Monat);
      const first = new Date(`${temp.slice(-2)}/02/${temp.slice(0, -2)}`);
      setFirstDate(first);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const reset = async () => {
      try {
        setResetOk(false);
        if (mode === 'gesamt') {
          await API.post('emilog', 'resetStunden', { body: { serialNumber } });
        } else {
          let tempHours;
          data.some((value) => {
            if (value.Monat === month) {
              tempHours = value.Ausfallstunden;
              return true;
            }
            return false;
          });
          const currentMonth = convertDate(new Date());
          // Bei aktuellem Monat muss nur im Shadow gelöscht werden
          if (month !== currentMonth) {
            await API.post('emilog', 'resetStunden', {
              body: {
                // Verfügbare Stunden minus die zu abziehenden
                serialNumber, month, hours: tempHours - days * 24,
              },
            });
          }
        }
        if (maxDays - days === 0) setDays('');
        setMaxDays((prevState) => prevState - days);
        fetch();
        setSuccess(true);
        setLoading(false);
      } catch (err) {
        setError(true);
        setLoading(false);
        console.error(err);
      }
    };

    if (resetOk) {
      reset();
    }
    // eslint-disable-next-line
  }, [resetOk]);

  useEffect(() => {
    setSuccess(false);
    setOffline(true);
    setRightVersion(false);
    if (active) {
      fetch();
    }
    // eslint-disable-next-line
  }, [serialNumber, active]);

  useEffect(() => {
    if (subGet.current) {
      subGet.current.unsubscribe();
    }
    if (subLive.current) {
      subLive.current.unsubscribe();
    }
    setCurrentHours(0);
    setTotalHours(0);
    setOffline(true);
    setRightVersion(false);
    if (active && serialNumber) {
      subGet.current = PubSub.subscribe(
        `$aws/things/EMI-LOG_${serialNumber}/shadow/get/accepted`,
      ).subscribe({
        next: ({ value }) => {
          const { reported } = value.state;
          if (reported?.status === 'offline') {
            setOffline(true);
          } else {
            setOffline(false);
          }
          if (reported?.status !== 'offline') {
            setOffline(false);
          }
          setCurrentHours(reported?.ausfallstunden_aktuell);
          setTotalHours(reported?.ausfallstunden_gesamt);
          if (getVersionTimestamp(value) > compatibleVersionTimestamp) {
            setRightVersion(true);
          } else {
            setRightVersion(false);
          }
        },
        error: (err) => console.error(err),
        close: () => console.log('Closed Get'),
      });

      subLive.current = PubSub.subscribe(
        `$aws/things/EMI-LOG_${serialNumber}/shadow/update/accepted`,
      ).subscribe({
        next: ({ value }) => {
          const { reported } = value.state;
          if (reported?.status === 'offline') {
            setOffline(true);
          } else {
            setOffline(false);
          }
          if (mode === 'gesamt') {
            if (reported?.ausfallstunden_gesamt === 0 && loadingRef.current) {
              resetOk(true);
            }
          } else if ((reported?.ausfallstunden_aktuell < currentHoursRef.current
            || reported?.ausfallstunden_gesamt < totalHoursRef.current)
            && loadingRef.current) {
            setResetOk(true);
          }
          if (reported?.ausfallstunden_aktuell) {
            setCurrentHours(reported?.ausfallstunden_aktuell);
          }
          if (reported?.ausfallstunden_gesamt) {
            setTotalHours(reported?.ausfallstunden_gesamt);
          }
        },
        error: (err) => console.error(err),
        close: () => console.log('Closed Update'),
      });

      setTimeout(() => {
        PubSub.publish(`$aws/things/EMI-LOG_${serialNumber}/shadow/get`, {});
      }, 1000);
    }
    return function cleanUp() {
      if (subLive.current) subLive.current.unsubscribe();
      if (subGet.current) subGet.current.unsubscribe();
    };
    // eslint-disable-next-line
  }, [serialNumber, active]);

  useEffect(() => {
    setMonth(convertDate(date));
  }, [date]);

  useEffect(() => {
    const currentMonth = convertDate(new Date());
    if (month === currentMonth) {
      setMaxDays(parseInt(currentHours / 24, 10));
      return;
    }
    let monthExists = false;
    data.some((value) => {
      if (value.Monat === month) {
        setMaxDays(value.Ausfallstunden / 24);
        monthExists = true;
        return true;
      }
      return false;
    });
    if (monthExists === false) setMaxDays(0);
  }, [month, data, currentHours]);

  function validNumber(input) {
    const number = input.replace(/[^0-9]/g, '');
    if (number > maxDays) return days;
    return number;
  }

  function handleDateChange(value) {
    setDate(value);
    setSuccess(false);
    setDays('');
  }

  function renderTitle() {
    return (
      <span className={classes.titleWrapper}>
        <span>Ausfallstunden</span>
        <Feedback
          error={error}
          success={success}
          successText="Erfolgreich gelöscht"
        />
        <Feedback
          error={!rightVersion && !offline}
          errorText="Softwareversion unterstützt die Funktion nicht"
          style={{ flex: '1 0', fontSize: '0.7rem' }}
        />
        <Feedback
          error={offline && active}
          errorText="EMI-LOG ist offline"
          style={{ flex: '1 0', fontSize: '0.7rem' }}
        />
      </span>
    );
  }

  function renderToggle() {
    return (
      <ToggleButtonGroup
        exclusive
        value={mode}
        onChange={handleToggle}
        className={classes.toggleGroup}
        disabled={!active || offline}
      >
        <ToggleButton
          disabled={!active}
          classes={{ root: classes.toggle }}
          value="einzeln"
        >
          Einzeln
        </ToggleButton>
        <ToggleButton
          disabled={!active}
          classes={{ root: classes.toggle }}
          value="gesamt"
        >
          Gesamt
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        title={(
          renderTitle()
        )}
        action={(
          renderToggle()
        )}
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2} alignItems="center">

          {mode === 'einzeln' ? (
            <>
              <Grid item md={4} xs={12}>

                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                  <DatePicker
                    views={['year', 'month']}
                    label="Monat"
                    minDate={firstDate || new Date()}
                    maxDate={new Date()}
                    value={date}
                    onChange={handleDateChange}
                    className={classes.datePicker}
                    size="small"
                    inputVariant="outlined"
                    fullWidth
                    disabled={!active || offline || !rightVersion}
                  />

                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={5} xs={12}>

                <TextField
                  size="small"
                  label={`Tage (maximal ${maxDays})`}
                  className={classes.input}
                  disabled={!active || maxDays === 0 || offline || !rightVersion}
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={days !== undefined ? days : ''}
                  onChange={(e) => setDays(validNumber(e.target.value))}
                />
              </Grid>
              <Grid item md={3}>

                <LoaderButton
                  isLoading={loading}
                  text="Löschen"
                  disabled={!active || maxDays === 0 || days === '' || offline || !rightVersion}
                  onClick={initReset}
                  noMargin
                  fullWidth
                />
              </Grid>
            </>
          ) : (
            <LoaderButton
              isLoading={loading}
              text="Ausfallstunden löschen"
              disabled={!active || offline}
              onClick={initReset}
              fullWidth
            />
          )}
          <Grid item xs={12} style={{ paddingBottom: 0, paddingTop: 4 }}>

            <Typography variant="caption">
              Ausfallstunden letzte 12 Monate:
              {' '}
              {totalHours}
              {' '}
              (ohne aktuellen Monat)
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
