/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
import React, {
  useState, useEffect, useContext, ChangeEvent,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import ToggleButton from '@material-ui/lab/ToggleButton';
// import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import InputAdornment from '@material-ui/core/InputAdornment';

import { isIP } from 'is-ip';
import API from '@aws-amplify/api';

import LoaderButton from '../LoaderButton';
import Feedback from '../Feedback';
import DeviceContext from '../../context/DeviceContext';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 32,
  },
  cardHeader: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: 16,
  },
  section: {
    padding: 18,
  },
  inputGroup: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  inputGroupNoMargin: {
    display: 'flex',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  gasSelect: {
    minWidth: 96,
  },
  selectElement: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  toggleGroup: {
    [theme.breakpoints.up('sm')]: {
      marginTop: -3,
      marginBottom: -3,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
    },
  },
  toggle: {
    padding: '6px 16px',
  },
  unit: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '0.95rem',
  },
  dropdown: {
    width: 150,
    marginTop: -8,
    [theme.breakpoints.up('sm')]: {
      marginBottom: -8,
    },
  },
}));

interface Gasarten {
  [key: string]: Gasart
}

function AnlagenInfos({
  gasarten,
}: { gasarten: Gasarten }) {
  const {
    fetchDevices, currentDevice, setCurrentDevice,
  } = useContext(DeviceContext);
  const active = currentDevice.serialNumber !== undefined;

  const [selectedGasart, setSelectedGasart] = useState<string | undefined>(undefined);
  const [type, setType] = useState<number | undefined>(undefined);

  const [address, setAddress] = useState<string | undefined>(undefined);
  const [betreiber, setBetreiber] = useState<string | undefined>(undefined);
  const [auftrag, setAuftrag] = useState<string | undefined>(undefined);

  const [coSerial, setCoSerial] = useState<string | undefined>(undefined);
  const [noxSerial, setNoxSerial] = useState<string | undefined>(undefined);

  const [dhcp, setDhcp] = useState(1);
  const [dhcpChanged, setDhcpChanged] = useState(false);

  const [ipAddress, setIpAddress] = useState<string | undefined>(undefined);
  const [subnet, setSubnet] = useState<string | undefined>(undefined);
  const [gateway, setGateway] = useState<string | undefined>(undefined);
  const [dns, setDns] = useState<string | undefined>(undefined);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const classes = useStyles();

  async function handleSubmit(event: any) {
    event.preventDefault();
    try {
      const body = {
        serialNumber: currentDevice.serialNumber,
        fields: {
          Typ: type,
          Adresse: (address === undefined || address === '') ? null : address,
          Betreiber: (betreiber === undefined || betreiber === '') ? null : betreiber,
          Auftrag: (auftrag === undefined || auftrag === '') ? null : auftrag,
          Gasart: (selectedGasart === undefined || selectedGasart === '') ? null : selectedGasart,
          DHCP: dhcpChanged ? dhcp : undefined,
          IP: (ipAddress === undefined || ipAddress === '') ? null : ipAddress,
          Subnetz: (subnet === undefined || subnet === '') ? null : subnet,
          Gateway: (gateway === undefined || gateway === '') ? null : gateway,
          DNS: (dns === undefined || dns === '') ? null : dns,
          Seriennummer_CO: coSerial,
          Seriennummer_NOx: noxSerial,
        },
      };
      setLoading(true);
      await API.post('emilog', 'updateInfos', { body });
      await fetchDevices();
      setSuccess(true);
      setCurrentDevice((prevState: Device) => ({
        ...prevState,
        Typ: type,
        Adresse: address,
        Betreiber: betreiber,
        Auftrag: auftrag,
        Gasart: selectedGasart,
        DHCP: dhcp,
        IP: ipAddress,
        Subnetz: subnet,
        Gateway: gateway,
        DNS: dns,
        Seriennummer_CO: coSerial,
        Seriennummer_NOx: noxSerial,
      }));
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }

  useEffect(() => {
    setType(currentDevice.Typ);
    setSelectedGasart(currentDevice.Gasart);
    setAddress(currentDevice.Adresse);
    setBetreiber(currentDevice.Betreiber);
    setAuftrag(currentDevice.Auftrag);

    setCoSerial(currentDevice.Seriennummer_CO);
    setNoxSerial(currentDevice.Seriennummer_NOx);

    setDhcp(currentDevice.DHCP === undefined ? 1 : Number(currentDevice.DHCP));
    setIpAddress(currentDevice.IP);
    setSubnet(currentDevice.Subnetz);
    setDns(currentDevice.DNS);
    setGateway(currentDevice.Gateway);
  }, [currentDevice]);

  /*
function handleTypeToggle(event, newType) {
  event.preventDefault();
  setType(newType);
  setTypeChanged(true);
}
*/

  const renderDropdown = () => (
    <FormControl className={classes.dropdown}>
      <InputLabel id="demo-simple-select-label">Typ</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={type ?? ''}
        onChange={(event) => setType(event.target.value as number)}
        disabled={!active}
      >
        <MenuItem value={0}>Normal</MenuItem>
        <MenuItem value={1}>Classic</MenuItem>
        <MenuItem value={2}>Permanent</MenuItem>
        <MenuItem value={3}>Basic</MenuItem>
      </Select>
    </FormControl>
  );

  /*
const Toggle = () => (
  <ToggleButtonGroup
    exclusive
    value={type}
    onChange={handleTypeToggle}
    className={classes.toggleGroup}
    disabled={!active}

  >
    <ToggleButton
      disabled={!active}
      classes={{ root: classes.toggle }}
      value={0}
    >
      Normal
    </ToggleButton>
    <ToggleButton
      disabled={!active}
      classes={{ root: classes.toggle }}
      value={1}
    >
      Classic
    </ToggleButton>
    <ToggleButton
      disabled={!active}
      classes={{ root: classes.toggle }}
      value={2}
    >
      Permanent
    </ToggleButton>
  </ToggleButtonGroup>
);
*/

  function handleDhcpToggle(event: ChangeEvent<HTMLInputElement>) {
    setDhcpChanged(true);
    setDhcp(event.target.checked ? 1 : 0);
  }

  function validateNetworksettings() {
    if (dhcp) return true;
    if (!dhcp && isIP(ipAddress as string) && isIP(gateway as string) && isIP(dns as string) && isIP(subnet as string)) return true;
    return false;
  }

  function validateNetworkAddress(string: string) {
    return string.replace(/[^A-Fa-f0-9.:]/g, '');
  }

  return (
    <Card>
      <FormControl onSubmit={handleSubmit}>
        <CardHeader
          className={classes.cardHeader}
          title="Anlageninfos"
          action={(
            // <Toggle />
            renderDropdown()
          )}
        />
        <Divider />
        <div className={classes.section}>
          <Grid container justifyContent="space-between">
            <Grid item lg={4} className={classes.selectElement}>
              <FormControl>
                <InputLabel id="gasart">Gasart</InputLabel>
                <Select
                  disabled={!active}
                  value={selectedGasart || ''}
                  labelId="Gasart"
                  onChange={(e) => setSelectedGasart(e.target.value as string)}
                  className={classes.gasSelect}
                >
                  <MenuItem value="" />
                  {Object.keys(gasarten).map((Gasart) => (
                    <MenuItem key={Gasart} value={Gasart}>{Gasart}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={2}>

                <Grid item xs={12} lg={4}>
                  <TextField
                    fullWidth
                    type="text"
                    disabled
                    size="small"
                    label="CO Warn"
                    value={gasarten[selectedGasart as string] !== undefined ? (gasarten[selectedGasart as string].CO_warn ?? '') : ''}
                    variant="outlined"
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end" disableTypography className={classes.unit}>mg/Nm³</InputAdornment>,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <TextField
                    type="text"
                    fullWidth
                    disabled
                    size="small"
                    label="CO Alarm"
                    value={gasarten[selectedGasart as string] !== undefined ? (gasarten[selectedGasart as string].CO_alarm ?? '') : ''}
                    variant="outlined"
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end" disableTypography className={classes.unit}>mg/Nm³</InputAdornment>,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <TextField
                    type="text"
                    fullWidth
                    disabled
                    // fullWidth
                    size="small"
                    label="Bezugssauerstoff"
                    value={gasarten[selectedGasart as string] !== undefined ? (gasarten[selectedGasart as string].O_B ?? '') : ''}
                    variant="outlined"
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end" disableTypography className={classes.unit}>%</InputAdornment>,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>

                  <TextField
                    type="text"
                    fullWidth
                    disabled
                    size="small"
                    label="NOₓ Warn"
                    value={gasarten[selectedGasart as string] !== undefined ? (gasarten[selectedGasart as string].NOx_warn ?? '') : ''}
                    variant="outlined"
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end" disableTypography className={classes.unit}>mg/Nm³</InputAdornment>,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>

                  <TextField
                    type="text"
                    fullWidth
                    disabled
                    size="small"
                    label="NOₓ Alarm"
                    value={gasarten[selectedGasart as string] !== undefined ? (gasarten[selectedGasart as string].NOx_alarm ?? '') : ''}
                    variant="outlined"
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end" disableTypography className={classes.unit}>mg/Nm³</InputAdornment>,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

              </Grid>

            </Grid>
          </Grid>
        </div>
        <Divider />
        <div style={{ padding: '16px 16px 8px 16px ' }}>
          <TextField
            type="text"
            disabled={!active}
            label="Adresse"
            value={address || ''}
            onChange={(e) => setAddress(e.target.value)}
            fullWidth
            variant="outlined"
            size="small"

          />
        </div>
        <div style={{ padding: '11px 16px' }}>
          <TextField
            type="text"
            disabled={!active}
            label="Betreiber"
            value={betreiber || ''}
            onChange={(e) => setBetreiber(e.target.value)}
            fullWidth
            variant="outlined"
            size="small"

          />

        </div>
        <div style={{ padding: '8px 16px 16px 16px ' }}>
          <TextField
            type="text"
            disabled={!active}
            label="Auftrag"
            value={auftrag || ''}
            onChange={(e) => setAuftrag(e.target.value)}
            fullWidth
            variant="outlined"
            size="small"

          />

        </div>
        <Divider />

        <div className={classes.section}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <TextField
                size="small"
                label="Seriennummer CO"
                fullWidth
                disabled={!active}
                variant="outlined"
                value={coSerial !== undefined ? coSerial : ''}
                onChange={(e) => setCoSerial(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                size="small"
                label="Seriennummer NOₓ"
                fullWidth
                disabled={!active}
                variant="outlined"
                value={noxSerial !== undefined ? noxSerial : ''}
                onChange={(e) => setNoxSerial(e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
        <Divider />

        <div className={classes.section}>
          <Grid container justifyContent="space-between">
            <Grid item lg={3} className={classes.selectElement}>
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Switch
                      color="primary"
                      checked={dhcp === 1}
                      onChange={handleDhcpToggle}
                      disabled={!active}
                    />
                  )}
                  label="DHCP"
                />
              </FormGroup>
            </Grid>
            <Grid item lg={9} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    disabled={!active}
                    type="text"
                    size="small"
                    label="IP-Adresse"
                    variant="outlined"
                    value={ipAddress || ''}
                    onChange={(e) => setIpAddress(validateNetworkAddress(e.target.value))}
                    required={!dhcp}
                    error={!isIP(ipAddress as string) && !!ipAddress}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    disabled={!active}
                    type="text"
                    size="small"
                    label="Subnetzmaske"
                    variant="outlined"
                    value={subnet || ''}
                    onChange={(e) => setSubnet(e.target.value)}
                    required={!dhcp}
                    error={!isIP(subnet as string) && !!subnet}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    disabled={!active}
                    type="text"
                    size="small"
                    label="Standardgateway"
                    variant="outlined"
                    value={gateway || ''}
                    onChange={(e) => setGateway(e.target.value)}
                    required={!dhcp}
                    error={!isIP(gateway as string) && !!gateway}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    disabled={!active}
                    type="text"
                    size="small"
                    label="DNS"
                    variant="outlined"
                    value={dns || ''}
                    onChange={(e) => setDns(e.target.value)}
                    required={!dhcp}
                    error={!isIP(dns as string) && !!dns}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Divider />

        <CardActions className={classes.cardActions}>
          <Feedback
            error={error}
            success={success}
          />
          <LoaderButton
            isLoading={loading}
            disabled={!active || !validateNetworksettings()}
            onClick={handleSubmit}
          />
        </CardActions>
      </FormControl>
    </Card>

  );
}

export default AnlagenInfos;
