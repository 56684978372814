import React, { useEffect, useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Skeleton from '@material-ui/lab/Skeleton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import RefreshIcon from '@material-ui/icons/Refresh';
import API from '@aws-amplify/api';

import convertTimestamp from '../../../utils/convertTimestamp';
import capitalize from '../../../utils/capitalize';

interface HinweisViewProps {
  handleMenuOpen: Function
  serialNumber: string
}

interface Hinweis {
  sensorType: string
  hinweisType: string
  timestamp: number
}

export default function HinweisView({ handleMenuOpen, serialNumber }: HinweisViewProps) {
  const [data, setData] = useState<Hinweis[]>([]);
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);

  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      const res = await API.get('emilog', 'getHinweise', {
        queryStringParameters: {
          serialNumber,
        },
      });
      setLoading(false);
      setData(res);
    } catch (err: any) {
      setLoading(false);
      if (err.response.status === 404) {
        setNoData(true);
      }
      console.log(err);
    }
  }, [serialNumber]);

  useEffect(() => {
    setData([]);
    if (typeof serialNumber !== 'undefined') {
      fetch();
    }
  }, [serialNumber, fetch]);

  const renderCardTitle = () => (
    <>
      {/* @ts-ignore */}
      <ButtonBase onClick={handleMenuOpen}>
        <Typography variant="h5">Alarme/Warnungen</Typography>
        <KeyboardArrowDownIcon />
      </ButtonBase>
    </>
  );

  const renderRefreshButton = () => (
    <Button variant="outlined" onClick={fetch}>
      <RefreshIcon />
    </Button>
  );

  return (
    <>
      <CardHeader
        title={(renderCardTitle())}
        action={renderRefreshButton()}
      />
      <Divider />
      <CardContent>
        {loading === true ? (
          <>
            <Skeleton key="1" style={{ marginBottom: 10 }} variant="text" />
            <Skeleton key="2" style={{ marginBottom: 10 }} variant="text" />
            <Skeleton key="4" style={{ marginBottom: 10 }} variant="text" />
          </>
        ) : (
          <Grid container spacing={1}>
            {noData ? (
              <Typography>Keine Daten vorhanden</Typography>
            ) : (
              <>
                {data.map((entry) => (
                  <React.Fragment key={`${entry.timestamp}-${entry.hinweisType}`}>
                    <Grid item xs={4}>
                      {convertTimestamp(entry.timestamp)}
                    </Grid>
                    <Grid item xs={4}>
                      {entry.sensorType}
                    </Grid>
                    <Grid item xs={4}>
                      {capitalize(entry.hinweisType)}
                    </Grid>
                  </React.Fragment>
                ))}
              </>
            )}
          </Grid>
        )}

      </CardContent>
    </>

  );
}
