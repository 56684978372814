import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';

import EmilogRoute from './emilog/components/PrivateRoute';
import PublicRoute from './emilog/components/PublicRoute';
import { AuthProvider } from './emilog/context/AuthContext';
import { DeviceProvider } from './emilog/context/DeviceContext';
import { SearchProvider } from './emilog/context/SearchContext';
import theme from './theme';

import Login from './emilog/components/Login/Login';
import Dashboard from './emilog/components/Dashboard/Dashboard';
import CalUser from './emilog/components/CalUser/CalUser';
import EmiViewUser from './emilog/components/EmiViewUser/EmiViewUser';
import Setup from './emilog/components/Setup/Setup';
import Klassierung from './emilog/components/Klassierung/Klassierung';
import Einstellungen from './emilog/components/Einstellungen/Einstellungen';
import Error from './emilog/components/Error/Error';
import Visualisierung from './emilog/components/Visualisierung/Visualisierung';
import Gasarten from './emilog/components/Gasarten/Gasarten';
import Logbuch from './emilog/components/Logbuch/Logbuch';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AuthProvider>
          <DeviceProvider>
            <SearchProvider>
              <Switch>
                <PublicRoute exact path="/login" component={Login} />
                <PublicRoute exact path="/error" component={Error} />
                <EmilogRoute exact path="/viewNutzer" component={EmiViewUser} />
                <EmilogRoute exact path="/calNutzer" component={CalUser} />
                <EmilogRoute exact path="/setup" component={Setup} />
                <EmilogRoute exact path="/setup/:serialNumber" component={Setup} />
                <EmilogRoute exact path="/einstellungen" component={Einstellungen} />
                <EmilogRoute exact path="/einstellungen/:serialNumber" component={Einstellungen} />
                <EmilogRoute exact path="/visualisierung" component={Visualisierung} />
                <EmilogRoute exact path="/visualisierung/:serialNumber" component={Visualisierung} />
                <EmilogRoute exact path="/klassierung" component={Klassierung} />
                <EmilogRoute exact path="/klassierung/:serialNumber" component={Klassierung} />
                <EmilogRoute exact path="/gasarten" component={Gasarten} />
                <EmilogRoute exact path="/logbuch" component={Logbuch} />
                <EmilogRoute exact path="/logbuch/:serialNumber" component={Logbuch} />
                <EmilogRoute exact path="/" component={Dashboard} />
                <Redirect to="/" />
              </Switch>
            </SearchProvider>
          </DeviceProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
