const prod = {
  apiGateway: {
    REGION: 'eu-central-1',
    URL: 'https://0efrralwdl.execute-api.eu-central-1.amazonaws.com/prod/',
  },
  transferLambda: {
    URL: 'https://lambda.eu-central-1.amazonaws.com/2015-03-31/functions/arn:aws:lambda:eu-central-1:130698894937:function:emilog-transferData/invocations',
  },
  protocolLambda: {
    URL: 'https://lambda.eu-central-1.amazonaws.com/2015-03-31/functions/arn:aws:lambda:eu-central-1:130698894937:function:emilog-protocol-download/invocations',
  },
  resetDataLambda: {
    URL: 'https://lambda.eu-central-1.amazonaws.com/2015-03-31/functions/arn:aws:lambda:eu-central-1:130698894937:function:emilog-reset-data/invocations',
  },
  cognito: {
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_opA91j5gt',
    APP_CLIENT_ID: '4hksv9huev0f5thgofi4ugbhfi',
    IDENTITY_POOL_ID: 'eu-central-1:eba05d5b-0f63-4e21-a110-f7f74e65e8d5',
  },
};

const dev = {
  apiGateway: {
    REGION: 'eu-central-1',
    URL: 'https://gu08ob0o3l.execute-api.eu-central-1.amazonaws.com/dev/',
  },
  transferLambda: {
    URL: 'https://lambda.eu-central-1.amazonaws.com/2015-03-31/functions/arn:aws:lambda:eu-central-1:130698894937:function:emilog-transferData/invocations',
  },
  protocolLambda: {
    URL: 'https://lambda.eu-central-1.amazonaws.com/2015-03-31/functions/arn:aws:lambda:eu-central-1:130698894937:function:emilog-protocol-download/invocations',
  },
  resetDataLambda: {
    URL: 'https://lambda.eu-central-1.amazonaws.com/2015-03-31/functions/arn:aws:lambda:eu-central-1:130698894937:function:emilog-reset-data/invocations',
  },
  cognito: {
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_opA91j5gt',
    APP_CLIENT_ID: '4hksv9huev0f5thgofi4ugbhfi',
    IDENTITY_POOL_ID: 'eu-central-1:eba05d5b-0f63-4e21-a110-f7f74e65e8d5',
  },
};

const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;
export default config;
