import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DeviceContext from '../emilog/context/DeviceContext';

export function useRouting(site: string) {
  const {
    currentDevice, devices, setCurrentDevice, loading,
  } = useContext(DeviceContext);

  const { serialNumber: routeSerial } = useParams<PathParams>();
  useEffect(() => {
    document.title = `${site} - EMI-LOG Adminportal`;

    devices.forEach((device) => {
      if (device.serialNumber === routeSerial) {
        setCurrentDevice(device);
        document.title = `${site} ${routeSerial} - EMI-LOG Adminportal`;
      }
    });
  }, [routeSerial, devices, setCurrentDevice]);
  return {
    currentDevice, devices, loading, setCurrentDevice,
  };
}
