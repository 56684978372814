/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import API from '@aws-amplify/api';

import { sortData, getSorting } from '../../../utils/sortTable';
import CalNutzerTableHead from './CalUserTableHead';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    height: '100%',
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    minWidth: 600,
    [theme.breakpoints.down('md')]: {
      minWidth: 400,
    },
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    paddingLeft: 24,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 12,
    },
  },
  paginationActions: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiIconButton-root': {
        padding: 0,
      },
      marginLeft: 8,
    },
  },
  paginationToolbar: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  paginationInput: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 8,
    },
  },
  cellWidth: {
    maxWidth: '28vh',
  },
  editingCell: {
    padding: 12,
  },
  // '@global': {
  //   '.MuiInputBase-root': {
  //     fontSize: '1rem',
  //   },
  // },
}));

interface CalUser {
  email: string
  institut: string
  name: string
}

export default function CalUserTable() {
  const classes = useStyles();

  const [order, setOrder] = useState<SortingOrder>('asc');
  const [orderBy, setOrderBy] = useState('email');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [data, setData] = useState<CalUser[]>([]);

  const handleRequestSort = (event: any, property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function fetch() {
    try {
      const res = await API.get('emilog', 'listCalUsers', {});
      setData(res);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Toolbar variant="dense">
            <Typography variant="h5">Registrierte Nutzer</Typography>
          </Toolbar>
          <Table className={classes.table} aria-label="simple table">
            <CalNutzerTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {sortData(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(({
                  email, institut, name,
                }, index) => {
                  const labelId = `nutzer-table-${index}`;

                  return (
                    <TableRow
                      tabIndex={-1}
                      key={email}
                      hover
                    >
                      <TableCell align="left" component="th" id={labelId} scope="row">
                        {email || ''}
                      </TableCell>
                      <TableCell align="left">
                        {name || '-'}
                      </TableCell>
                      <TableCell align="left" component="th" id={labelId} scope="row">
                        {institut || '-'}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <div className={classes.tableFooter}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={data ? data.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Nutzer pro Seite"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={{
              actions: classes.paginationActions,
              toolbar: classes.paginationToolbar,
              input: classes.paginationInput,
            }}
          />
        </div>
      </Paper>
    </div>
  );
}
