/* eslint-disable no-console */
import React, { createContext, useMemo, useState } from 'react';

interface ContextProps {
  filter: string
  setFilter: Function
  rowsPerPage: number
  setRowsPerPage: Function
  order: 'asc' | 'desc'
  setOrder: Function
  orderBy: string
  setOrderBy: Function
  page: number
  setPage: Function
}

const SearchContext = createContext<ContextProps>({
  filter: '',
  order: 'asc',
  orderBy: 'MAC',
  page: 0,
  rowsPerPage: 10,
  setPage: () => { },
  setOrder: () => { },
  setOrderBy: () => { },
  setRowsPerPage: () => { },
  setFilter: () => { },
});

/*
  Enthält Suchfilter und Auswahl der Seite, um diese auch beim Seitenwechsel zum Dashboard zurück
  zur Verfügung zu stellen
*/
function SearchProvider({ children }: { children: React.ReactChild }) {
  const [filter, setFilter] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('MAC');

  const value = useMemo(() => ({
    filter, setFilter, rowsPerPage, setRowsPerPage, page, setPage, order, setOrder, orderBy, setOrderBy,
  }), [filter, rowsPerPage, page, order, orderBy]);

  return (
    <SearchContext.Provider value={value}>
      {children}
    </SearchContext.Provider>
  );
}

export default SearchContext;
export { SearchProvider };
