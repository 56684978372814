import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import API from '@aws-amplify/api';

import LoaderButton from '../LoaderButton';

interface AddDeviceDialogProps {
  open: boolean
  onClose: Function
  onSubmit: Function
  rows: Device[]
}

export default function AddDeviceDialog({
  open, onClose, onSubmit, rows,
}: AddDeviceDialogProps) {
  const [serialNumber, setSerialNumber] = useState('');

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [loading, setLoading] = useState(false);

  async function checkSerialNumber() {
    let exists = false;
    rows.forEach((row) => {
      if (Object.values(row).includes(serialNumber)) {
        exists = true;
      }
    });
    return exists;
  }
  function handleClose() {
    setError(false);
    setErrorText('');
    onClose();
  }

  async function handleSubmit(event: any) {
    event.preventDefault();
    setError(false);
    setErrorText('');
    const alreadyExists = await checkSerialNumber();
    if (alreadyExists) {
      setErrorText('Seriennummer ist bereits registriert');
      setError(true);
      return;
    }
    try {
      setLoading(true);
      await API.post('emilog', 'registerDevice', { body: { serialNumber } });
      onSubmit();
      setLoading(false);
      setError(false);
    } catch (err: any) {
      setLoading(false);
      if (err.response.status === 404) {
        setErrorText('Seriennummer existiert nicht');
        setError(true);
        return;
      }
      setErrorText('Etwas ist schiefgelaufen');
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>EMI-LOG hinzufügen</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>
            Um einen neuen EMI-Log hinzuzufügen, geben Sie bitte die Seriennummer ein.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                id="serialNumber"
                label="Seriennummer"
                type="text"
                onChange={(e) => setSerialNumber(e.target.value)}
                required
                autoFocus
                error={error}
                helperText={errorText}
              />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          {/* @ts-ignore */}
          <Button onClick={onClose}>Abbrechen</Button>
          <LoaderButton
            text="Bestätigen"
            isLoading={loading}
            variant="text"
          />
        </DialogActions>
      </form>
    </Dialog>
  );
}
