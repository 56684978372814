import React, { useState, useContext } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import API from '@aws-amplify/api';
import { Cancel, CheckCircle } from '@material-ui/icons';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AuthContext from '../../context/AuthContext';
import capitalize from '../../../utils/capitalize';

interface AddLogbookEntryProps {
  setAddingNewEntry: React.Dispatch<React.SetStateAction<boolean>>
  serialNumber: string
  fetch: () => void
  shouldSendMail: boolean
}

export default function AddLogbookEntry({
  setAddingNewEntry, serialNumber, fetch, shouldSendMail,
}: AddLogbookEntryProps) {
  const mobile = useMediaQuery('(max-width:600px)');
  console.log(shouldSendMail);
  const { currentUser } = useContext(AuthContext);
  const [firstName, lastName] = currentUser?.user.attributes.email.split('@')[0].split('.') ?? ['', ''];
  const author = `${capitalize(firstName)} ${capitalize(lastName)}`;
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  async function handleSubmit() {
    setSubmitting(true);
    await API.post('emilog', 'addLogbookEntry', {
      body: {
        serialNumber,
        author,
        messageContent: message,
      },
    });
    setAddingNewEntry(false);
    setSubmitting(false);
    fetch();
    setMessage('');
  }
  return (
    <TableRow>
      {!mobile ? (
        <>
          <TableCell style={{
            verticalAlign: 'baseline',
          }}
          >
            Wird automatisch ausgefüllt
          </TableCell>
          <TableCell style={{
            verticalAlign: 'baseline',
          }}
          >
            {author}
          </TableCell>
        </>
      ) : (
        null
      )}
      <TableCell colSpan={mobile ? 2 : 1}>
        <div style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <TextField
            value={message}
            onChange={handleChange}
            style={{
              flexGrow: 1,
            }}
            placeholder="Hier Text eingeben"
            multiline
          />
          {submitting ? (
            <CircularProgress
              size={22}
              style={{
                marginLeft: 16,
              }}
            />
          ) : (
            <>
              <IconButton
                size="small"
                style={{
                  color: message.length > 0 ? 'green' : '',
                }}
                onClick={handleSubmit}
                disabled={message.length < 1}
              >
                <CheckCircle />
              </IconButton>
              <IconButton
                size="small"
                style={{
                  color: 'red',
                }}
                onClick={() => setAddingNewEntry(false)}
              >
                <Cancel />
              </IconButton>
            </>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}
