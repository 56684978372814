/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, {
  useState,
} from 'react';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ProtocolView from './ProtocolView';
import ShadowView from './ShadowView';
import HinweisView from './HinweisView';
import StoerungView from './StoerungView';

const useStyles = makeStyles(() => ({
  card: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
    height: 697, // TODO: anpassen ggf'80vh',
  },
}));

interface DataCardProps {
  serialNumber: string
  active: boolean
}

export default function DataCard({ serialNumber, active } : DataCardProps) {
  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = useState(null);

  const [mode, setMode] = useState(0);

  const handleMenuOpen = (event: any) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleMenuItemClick = (_event: any, index: number) => {
    setMode(index);
    setMenuAnchor(null);
  };

  return (
    <>
      <Card className={classes.card}>
        {mode === 0 ? (
          <ShadowView
            serialNumber={serialNumber}
            active={active}
            handleMenuOpen={handleMenuOpen}
          />
        ) : mode === 1 ? (
          <ProtocolView
            handleMenuOpen={handleMenuOpen}
            serialNumber={serialNumber}
          />
        ) : mode === 2 ? (
          <StoerungView
            handleMenuOpen={handleMenuOpen}
            serialNumber={serialNumber}
          />
        ) : (
          <HinweisView
            handleMenuOpen={handleMenuOpen}
            serialNumber={serialNumber}
          />
        )}
      </Card>
      <Menu
        id="simple-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        <MenuItem
          selected={mode === 0}
          onClick={(event) => handleMenuItemClick(event, 0)}
        >
          Shadow
        </MenuItem>
        <MenuItem
          selected={mode === 1}
          onClick={(event) => handleMenuItemClick(event, 1)}
        >
          Protokoll
        </MenuItem>
        <MenuItem
          selected={mode === 2}
          onClick={(event) => handleMenuItemClick(event, 2)}
        >
          Störungen
        </MenuItem>
        <MenuItem
          selected={mode === 3}
          onClick={(event) => handleMenuItemClick(event, 3)}
        >
          Alarme/Warnung
        </MenuItem>
      </Menu>
    </>
  );
}
